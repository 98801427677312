import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from "react-router-dom";
import { useState, useEffect, useCallback, } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import axios from "axios";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import ClearAllTwoToneIcon from '@mui/icons-material/ClearAllTwoTone';
import { Hidden, Tooltip } from '@mui/material';
//import Moment from 'react-moment';
import { Button, Input, Row } from "reactstrap";
import Menu from './Menu';
import Header from './Header';
import Header2 from './Header2';
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import filter from '@inovua/reactdatagrid-community/filter'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import { TypeFilterValue } from '@inovua/reactdatagrid-community/types';
//import { render } from '@fullcalendar/core/preact';
import moment from 'moment';
import { BounceLoader } from 'react-spinners';
//import { em } from '@fullcalendar/core/internal-common';
import { Column, ReportObject, renderDate } from "./MagniTypes";
import { CheckBox } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import APICalls from './APICalls';
import * as ExcelJS from 'exceljs';
import { ToastContainer } from 'react-toastify';






window.moment = moment;

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: "#9AABE8",
//     color: "#0E226A",
//     fontSize: 18,

//   },
//   [`&.${tableCellClasses.body}`]: {
//     backgroundColor: "#E5E9F9",
//     color: "#0E226A",
//     fontSize: 14,
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   '&:nth-of-type(odd)': {
//     backgroundColor: theme.palette.action.hover,
//   },

// }));


function TablePaginationActions(props: any) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: any) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: any) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: any) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: any) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


export default function ConditionReports() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // 768px as breakpoint for mobile
  const [loading, setLoading] = useState(true);
  const [rows, setData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [flattenData, setFlattenData] = useState<any[]>([]);
  const [reload, setReload] = useState(false);


  // const [taskTypeoptions, setOptions] = useState<any[]>([]);
  // const [createdBy, setCreatedBy] = useState<any[]>([]);
  // const [assignedTo, setAssignedTo] = useState<any[]>([]);
  // const [proiorities, setProiorities] = useState<any[]>([]);
  // const [selectedOption, setOptionData] = useState([]);
  // const [Taskid, setTaskid] = useState([]);
  // const [customerOptions, setCustomerOptions] = useState<any[]>([]);
  // const [productOptions, setProductOptions] = useState<any[]>([]);
  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);
  // const [createdReports, setCreatedReports] = useState<{ id: string; label: string }[]>([]);





  const apiCalls = new APICalls();

  useEffect(() => {
    setLoading(true);
    GetAllReports();

    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      setIsMobile(window.innerWidth <= 768);
      setWindowWidth(width);

      setIsLandscape(width > height);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, [reload]);

  const GetAllReports = async () => {
    const response = await apiCalls.GetAllReports();
    setData(response.data);
    console.log(response.data)
    const data: ReportObject[] = response.data;
    setLoading(false);
  }



  const [windowWidth, setWindowWidth] = useState(window.innerWidth);



  const commonHeaderStyle = {
    backgroundColor: '#025444',
    color: '#FFFFFF',
  };





  const columns = [
    { name: 'CRid', header: 'CR #', headerProps: { style: commonHeaderStyle, width: '10px' } },
    {
      name: 'DateCreated', header: 'Date Created', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return moment(value).format('YYYY/MM/DD') }
    },
    { name: 'Name', header: 'Name', headerProps: { style: commonHeaderStyle } },
    { name: 'Product', header: 'Product', headerProps: { style: commonHeaderStyle } },
    { name: 'Lot', header: 'Lot', headerProps: { style: commonHeaderStyle } },
    // {
    //   name: 'Rma', header: 'Rma', headerProps: { style: commonHeaderStyle },
    //   render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    // },
    { name: 'Initiator', header: 'Initiator', headerProps: { style: commonHeaderStyle } },
    { name: 'AssignedTo', header: 'Assigned To', headerProps: { style: commonHeaderStyle } },
    {
      name: 'CompleteDate', header: 'Complete Date', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value ? moment(value).format('YYYY/MM/DD') : 'N/A'; }
    },
    {
      name: 'Rpt', header: 'Report Closed?', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    { name: 'Approval1', header: 'Approval 1', headerProps: { style: commonHeaderStyle } },
    { name: 'Approval2', header: 'Approval 2', headerProps: { style: commonHeaderStyle } },
    { name: 'Approval3', header: 'Approval 3', headerProps: { style: commonHeaderStyle } },
    {
      name: 'CANeeded', header: 'CA Needed', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === 1 ? 'Yes' : 'No' }
    },
    {
      name: 'Complaint', header: 'Complaint', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    { name: 'Desc', header: 'Desc', headerProps: { style: commonHeaderStyle } },
    { name: 'CFdesc', header: 'Cause Fault Desc', headerProps: { style: commonHeaderStyle } },
    { name: 'CompanyName', header: 'Company Name', headerProps: { style: commonHeaderStyle } },
    { name: 'Level', header: 'Level', headerProps: { style: commonHeaderStyle } },
    { name: 'Basis', header: 'Comments', headerProps: { style: commonHeaderStyle } },
    { name: 'QAAuthSign', header: 'QA Auth Sign', headerProps: { style: commonHeaderStyle } },
    { name: 'NoDrums', header: '# of Drums', headerProps: { style: commonHeaderStyle } },
    { name: 'NoTotes', header: '# of Totes', headerProps: { style: commonHeaderStyle } },
    { name: 'DrumGal', header: 'Drum Gallons', headerProps: { style: commonHeaderStyle } },
    { name: 'ToteGal', header: 'Tote Gallons', headerProps: { style: commonHeaderStyle } },
    { name: 'DrumGWeigh', header: 'Drum Weight lb', headerProps: { style: commonHeaderStyle } },
    { name: 'ToteGWeigh', header: 'Tote Weight lb', headerProps: { style: commonHeaderStyle } },
    // {
    //   name: 'RTnCredit', header: 'Return Credit', headerProps: { style: commonHeaderStyle },
    //   render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    // },
    // {
    //   name: 'RtnEval', header: 'Return Eval', headerProps: { style: commonHeaderStyle },
    //   render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    // },
    // {
    //   name: 'MatlRemix', header: 'Material Remix', headerProps: { style: commonHeaderStyle },
    //   render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    // },
    // { name: 'RestockChg', header: 'Restock Charge', headerProps: { style: commonHeaderStyle } },
    // { name: 'AuthRtnSign', header: 'RMA Initiator signature', headerProps: { style: commonHeaderStyle } },
    // { name: 'AcknRtnBy', header: 'AcknRtnBy', headerProps: { style: commonHeaderStyle } },
    // { name: 'RtnMComments', header: 'RMA Comments', headerProps: { style: commonHeaderStyle } },
    // { name: 'RtnMCommentsSign', header: 'RMA Receiving Signature', headerProps: { style: commonHeaderStyle } },
    {
      name: 'ActAssignDate', header: 'Action Assign Date', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value ? moment(value).format('YYYY/MM/DD') : 'N/A'; }
    },
    { name: 'AnalysisNM', header: 'Analysis of Nonconformance/Condition', headerProps: { style: commonHeaderStyle } },
    {
      name: 'Rework', header: 'Rework', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    {
      name: 'Repair', header: 'Repair', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    {
      name: 'Scrap', header: 'Scrap', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    {
      name: 'UseAsIs', header: 'Use As Is', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    {
      name: 'Reject', header: 'Reject', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    {
      name: 'Escalate', header: 'Escalate', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    { name: 'InstDisp', header: 'Instruction for Disposition', headerProps: { style: commonHeaderStyle } },
    { name: 'FinalInsp', header: 'Final Inspection/Conclusion', headerProps: { style: commonHeaderStyle } },
    // { name: 'TrendCode', header: 'Trend Code', headerProps: { style: commonHeaderStyle } },
    // { name: 'TrendCode2', header: 'Trend Code 2', headerProps: { style: commonHeaderStyle } },
    // { name: 'TrendCode3', header: 'Trend Code 3', headerProps: { style: commonHeaderStyle } },
    // { name: 'TrendCode4', header: 'Trend Code 4', headerProps: { style: commonHeaderStyle } },
    // { name: 'TrendCode5', header: 'Trend Code 5', headerProps: { style: commonHeaderStyle } },
    // { name: 'TrendCode5b', header: 'Trend Code 5b', headerProps: { style: commonHeaderStyle } },
    // { name: 'TrendCode6', header: 'Trend Code 6', headerProps: { style: commonHeaderStyle } },
    // { name: 'EAPAComments', header: 'EAPA Comments', headerProps: { style: commonHeaderStyle } },
    {
      name: 'EAPA', header: 'EAPA', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    // { name: 'AnalysisRef', header: 'Analysis Ref', headerProps: { style: commonHeaderStyle } },
    // { name: 'AnalysisRef2', header: 'Analysis Ref 2', headerProps: { style: commonHeaderStyle } },
    // { name: 'AnalysisRef3', header: 'Analysis Ref 3', headerProps: { style: commonHeaderStyle } },
    // { name: 'AnalysisRef4', header: 'Analysis Ref 4', headerProps: { style: commonHeaderStyle } },
    // { name: 'AnalysisRef5', header: 'Analysis Ref 5', headerProps: { style: commonHeaderStyle } },
    { name: 'SCAR', header: 'SCAR', headerProps: { style: commonHeaderStyle } },
    {
      name: 'Approve1Date', header: 'Approval 1 Date', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value ? moment(value).format('YYYY/MM/DD') : 'N/A'; }
    },
    {
      name: 'Approve2Date', header: 'Approval 2 Date', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value ? moment(value).format('YYYY/MM/DD') : 'N/A'; }
    },
    {
      name: 'Approve3Date', header: 'Approval 3 Date', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value ? moment(value).format('YYYY/MM/DD') : 'N/A'; }
    },
    { name: 'InterimAction', header: 'Interim Action', headerProps: { style: commonHeaderStyle } },
    { name: 'RootCause', header: 'Root Cause', headerProps: { style: commonHeaderStyle } },
    { name: 'CPA', header: 'CPA', headerProps: { style: commonHeaderStyle } },
    { name: 'CPASign', header: 'CPA Signature', headerProps: { style: commonHeaderStyle } },
    {
      name: 'CPADate', header: 'CPA Date', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value ? moment(value).format('YYYY/MM/DD') : 'N/A'; }
    },
    { name: 'CPAFollowUP', header: 'CPA Follow Up', headerProps: { style: commonHeaderStyle } },
    {
      name: 'CompleteDateCA', header: 'Complete Date CA', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value ? moment(value).format('YYYY/MM/DD') : 'N/A'; }
    },
    {
      name: 'RptCloseCA', header: 'Rpt Close CA', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    { name: 'Approve1CA', header: 'Approval 1 CA', headerProps: { style: commonHeaderStyle } },
    {
      name: 'Approve1DateCA', header: 'Approval 1 Date CA', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value ? moment(value).format('YYYY/MM/DD') : 'N/A'; }
    },
    { name: 'Approve2CA', header: 'Approve2CA', headerProps: { style: commonHeaderStyle } },
    {
      name: 'Approve2DateCA', header: 'Approval 2 Date CA', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value ? moment(value).format('YYYY/MM/DD') : 'N/A'; }
    },
    { name: 'Approve3CA', header: 'Approval 3 CA', headerProps: { style: commonHeaderStyle } },
    {
      name: 'Approve3DateCA', header: 'Approval 3 Date CA', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value ? moment(value).format('YYYY/MM/DD') : 'N/A'; }
    },
    { name: 'ReturnCategory', header: 'Return Category', headerProps: { style: commonHeaderStyle } },
    {
      name: 'ExternalReject', header: 'External Reject', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    {
      name: 'InternalReject', header: 'Internal Reject', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }

    },
    {
      name: 'DidNotFollowProcedure', header: 'Did Not Follow Procedure', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    {
      name: 'ProcessChange', header: 'Process Change', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    {
      name: 'MISC', header: 'MISC', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    {
      name: 'OpportunityforImprovement', header: 'Opportunity for Improvement', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    {
      name: 'SCARIssued', header: 'SCAR Issued', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    {
      name: 'InternalAuditFinding', header: 'Internal Audit Finding', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    { name: 'SerialNumber', header: 'Serial Number', headerProps: { style: commonHeaderStyle } },
    { name: 'MagniDetroitCR', header: 'Magni Detroit CR', headerProps: { style: commonHeaderStyle } },
    { name: 'ResearchProject', header: 'Research Project', headerProps: { style: commonHeaderStyle } },
    { name: 'EstimatedCostofQuality', header: 'Estimated Cost of Quality', headerProps: { style: commonHeaderStyle } },
    { name: 'Department', header: 'Department', headerProps: { style: commonHeaderStyle } },
    { name: 'CustomerFeedbackCategory', header: 'Customer Feedback Category', headerProps: { style: commonHeaderStyle } },
    { name: 'CustomerComplaint', header: 'Customer Complaint', headerProps: { style: commonHeaderStyle } },
    { name: 'AnalyticalReport', header: 'Analytical Report', headerProps: { style: commonHeaderStyle } },
    {
      name: 'AuditFinding', header: 'Audit Finding', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    {
      name: 'ProposedAuditDate', header: 'Proposed Audit Date', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value ? moment(value).format('YYYY/MM/DD') : 'N/A'; }
    },
    {
      name: 'DidAssigneeCompleteSatisfactorily', header: 'Did Assignee Complete Satisfactorily', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    {
      name: 'MeasurableFinding', header: 'Measurable Finding', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    {
      name: 'Level1', header: 'Level 1', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    {
      name: 'Level2', header: 'Level 2', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    {
      name: 'Level3', header: 'Level 3', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    {
      name: 'Continuous Improvement', header: 'Continuous Improvement', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    {
      name: 'LastUpdate', header: 'Last Update', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value ? moment(value).format('YYYY/MM/DD') : 'N/A'; }
    },
    { name: 'Visc', header: 'Visc', headerProps: { style: commonHeaderStyle } },
    { name: 'WPG', header: 'WPG', headerProps: { style: commonHeaderStyle } },
    { name: 'WtSlds', header: 'WtSlds', headerProps: { style: commonHeaderStyle } },
    { name: 'Color', header: 'Color', headerProps: { style: commonHeaderStyle } },
    {
      name: 'ReclaimfromToteReturn', header: 'ReclaimfromToteReturn', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    { name: 'Sales', header: 'Sales', headerProps: { style: commonHeaderStyle } },
    {
      name: 'ResearchSupported', header: 'ResearchSupported', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },
    {
      name: 'Quarantine', header: 'Quarantine', headerProps: { style: commonHeaderStyle },
      render: ({ value, cellProps }: { value: any, cellProps: any }) => { return value === true ? 'Yes' : 'No' }
    },



  ]
  const [enableColumnAutosize, setEnableColumnAutosize] = useState(true);


  const [gridRef, setGridRef] = useState<any>(null);
  const SEPARATOR = ',';

  const downloadBlob = (blob: Blob, fileName = 'All Reports.csv') => {
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.position = 'absolute';
    link.style.visibility = 'hidden';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const exportCSV = () => {
    const columns = gridRef.current.visibleColumns;

    const header = columns.map((c: any) => c.name).join(SEPARATOR);
    const rows = gridRef.current.data.map((data: any) => columns.map((c: any) => {
      let cellData = data[c.id];
      return String(cellData).replace(/\n/g, ' ').replace(/,/g, ' and ').replace(/\r/g, ' ');
    }
    ).join(SEPARATOR));

    const contents = [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

    downloadBlob(blob);

  };

  /*

const exportCSV = () => {
    const columns = gridRef.current.visibleColumns;

    // Increase the width for each column by adding extra characters
    const header = columns.map((c: any) => `"${c.name}        "`).join(SEPARATOR); // Added extra spaces
    const rows = gridRef.current.data.map((data: any) => columns.map((c: any) => {
      let cellData = data[c.id];
      return `"${String(cellData).replace(/\n/g, ' ').replace(/,/g, ' and ').replace(/\r/g, ' ')}"`;
    }).join(SEPARATOR));

    const contents = [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

    downloadBlob(blob);  
};

*/




const emptyText = loading ? <BounceLoader color={"#123abc"} loading={true} size={100} /> : <b style={{
  padding: 60,
  border: '1px solid #ef9a9a',
  color: '#ef9a9a',
  borderRadius: 10,
  fontSize:'30px',
  borderWidth:'3px'
}}>No content found!</b>



  const filterValues = [
    { name: 'CRid', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'DateCreated', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Name', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Product', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Lot', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'AssignedTo', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'CompleteDate', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Approval1', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Approval2', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Approval3', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Desc', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'CFDesc', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'CompanyName', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Initiator', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Level', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Basis', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'QAAuthSign', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'NoDrums', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'NoTotes', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'DrumGal', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'ToteGal', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'DrumGWeigh', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'ToteGWeigh', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'ActAssignDate', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'AnalysisNM', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'InstDisp', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'FinalInsp', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'SCAR', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Approve1Date', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Approve2Date', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Approve3Date', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'InterimAction', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'RootCause', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'CPA', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'CPASign', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'CPADate', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'CPAFollowUP', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'CompleteDateCA', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Approve1CA', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Approve1DateCA', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Approve2CA', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Approve2DateCA', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Approve3CA', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Approve3DateCA', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'ReturnCategory', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'SerialNumber', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'MagniDetroitCR', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'ResearchProject', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'EstimatedCostofQuality', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Department', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'CustomerFeedbackCategory', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'CustomerComplaint', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'AnalyticalReport', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'ProposedAuditDate', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'LastUpdate', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Visc', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'WPG', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'WtSlds', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Color', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Sales', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },






  ]

  const navigate = useNavigate();

  // const handleColumnVisibilityChange = (rowProps: any) => {
  //   const baseUrl = window.location.origin;
  //   localStorage.setItem('redirecturl', '/conditionreports/crdetails/' + rowProps.data.CRid);
  //   console.log(`${baseUrl}/conditionreports/crdetails/${rowProps.data.CRid}`);
  //   window.open(`${baseUrl}/conditionreports/crdetails/${rowProps.data.CRid}`, '_blank', 'noopener,noreferrer');
  // }

  const onRowDoubleClick = useCallback((rowProps: any) => {

    const baseUrl = window.location.origin;
    localStorage.setItem('redirecturl', '/conditionreports/crdetails/' + rowProps.data.CRid);
    console.log(`${baseUrl}/conditionreports/crdetails/${rowProps.data.CRid}`);
    navigate('/conditionreports/crdetails/' + rowProps.data.CRid);


    //const newWindow = window.open('/conditionreports/crdetails/' + rowProps.data.CRid, '_blank', 'noopener,noreferrer');
    //if (newWindow) newWindow.opener = null;
    //toggle5(rowProps.data);
  }, [])

  const onRowClick = useCallback((rowProps: any) => {
    localStorage.setItem('redirecturl', '/conditionreports/crdetails/' + rowProps.data.CRid);
    window.open('/conditionreports/crdetails/' + rowProps.data.CRid, '_blank', 'noopener,noreferrer');
    //toggle5(rowProps.data);
  }, [])


  const onRenderRow = useCallback((rowProps: any) => {
    const { onClick, onDoubleClick, ...restProps } = rowProps;

    const handleDoubleClick = (event: any) => {
      onRowDoubleClick(rowProps);
      if (onDoubleClick) {
        onDoubleClick(event);
      }
    };

    const handleClick = (event: any) => {
      onRowClick(rowProps);
      if (onClick) {
        onClick(event);
      }
    };



    // Assuming that rows are rendered as div elements in the original grid.
    // This might not be correct and you might need to look for the proper way 
    // to render a row if the library does not expose its default row rendering component.
    return <div {...restProps} onClick={isMobile ? handleClick : undefined}
      onDoubleClick={isMobile ? undefined : handleDoubleClick} />;
  }, [onRowClick, onRowDoubleClick, isMobile]);

  const handleAddNewReportClick = () => {
    navigate('/conditionreports/addcr/');
  };


  type ReportFilter = "all reports" | "active reports" | "closed reports";

  const defaultFilter: ReportFilter = "all reports";


  const [selectedFilter, setSelectedFilter] = useState<ReportFilter>(defaultFilter);

  const handleFilterChange = async (newFilter: ReportFilter) => {
    setSelectedFilter(newFilter);

    if (newFilter === "all reports") {
      const response = await apiCalls.GetAllReports();
      setData(response.data);
    } else if (newFilter === "active reports") {
      const response = await apiCalls.GetActiveReports();
      setData(response.data);
    } else if (newFilter === "closed reports") {
      const response = await apiCalls.GetClosedReports();
      setData(response.data);
    }
  };

  return (
    <div style={{ backgroundColor: '#025444', color: '#FFFFFF' }}>
      <Header2 />
      <h5 style={{ textAlign: "center", fontSize: 30, marginTop: '-15px' }}>  Condition Reports </h5>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <label>
          <input
            type="radio"
            value="all reports"
            checked={selectedFilter === "all reports"}
            onChange={() => handleFilterChange("all reports")}
            style={{ marginLeft: '50px' }}
          />
          All Reports
        </label>
        <label>
          <input
            type="radio"
            value="active reports"
            checked={selectedFilter === "active reports"}
            onChange={() => handleFilterChange("active reports")}
            style={{ marginLeft: '50px' }}
          />
          Active Reports
        </label>
        <label>
          <input
            type="radio"
            value="closed reports"
            checked={selectedFilter === "closed reports"}
            onChange={() => handleFilterChange("closed reports")}
            style={{ marginLeft: '50px' }}
          />
          Closed Reports
        </label>
      </div>


      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
        <Button
          color="primary"
          style={{
            fontSize: '18px',
            padding: '5px 10px',
            margin: '0 5px',
            backgroundColor: '#FFFFFF',
            color: '#000000'
          }}
          onClick={exportCSV}
        >
          Export to CSV
        </Button>
        <Button
          color="primary"
          onClick={handleAddNewReportClick}
          style={{
            fontSize: '18px',
            padding: '5px 10px',
            margin: '0 5px',
            backgroundColor: '#FFFFFF',
            color: '#000000'
          }}
        >
          Add New Report
        </Button>
      </div>
      <ReactDataGrid
        handle={setGridRef}
        idProperty='CRid'
        licenseKey="-417513151-6082371292094400410-2087501165-417513151631937863"
        columns={columns}
        dataSource={rows}
        style={{ height: 'calc(102vh - 235px)', overflow: 'hidden' }}
        defaultFilterValue={filterValues}
        renderRow={onRenderRow}
        emptyText={emptyText}
        enableColumnAutosize={true}

      />


      <ToastContainer />
    </div>
  );


}