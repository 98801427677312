import { ChangeEvent } from "react";

export class ReportObject {
  public CRid: number = 0;
  public DateCreated: string = '';
  public Name: string = '';
  public Product: string = '';
  public Lot: number = 0;
  public Rma: boolean = false;
  public AssignedTo: string = '';
  public CompleteDate: string | null = '';
  public Rpt: boolean = false;
  public Approval1: string | null = '';
  public Approval2: string | null = '';
  public Approval3: string | null = '';
  public CANeeded: number = 0;
  public Complaint: boolean = false;
  public Desc: string = '';
  public CFdesc: string = '';
  public CompanyName: string = '';
  public Initiator: string = '';
  public Level: string = '';
  public Basis: string = '';
  public QAAuthSign: string | null = '';
  public NoDrums: string | null = '';
  public NoTotes: string | null = '';
  public DrumGal: number = 0;
  public ToteGal: number = 0;
  public DrumGWeigh: number = 0;
  public ToteGWeigh: number = 0;
  public RTnCredit: boolean = false;
  public RtnEval: boolean = false;
  public MatlRemix: boolean = false;
  public RestockChg: string | null = '';
  public AuthRtnSign: string | null = '';
  public AcknRtnBy: string | null = '';
  public RtnMComments: string | null = '';
  public RtnMCommentsSign: string | null = '';
  public ActAssignDate: string | null = '';
  public AnalysisNM: string | null = '';
  public Rework: boolean = false;
  public Repair: boolean = false;
  public Scrap: boolean = false;
  public UseAsIs: boolean = false;
  public Reject: boolean = false;
  public Escalate: boolean = false;
  public InstDisp: string | null = '';
  public FinalInsp: string | null = '';
  public TrendCode: string | null = '';
  public TrendCode2: string | null = '';
  public TrendCode3: string | null = '';
  public TrendCode4: string | null = '';
  public TrendCode5: string | null = '';
  public TrendCode5b: string | null = '';
  public TrendCode6: string | null = '';
  public EAPAComments: string | null = '';
  public EAPA: boolean = false;
  public AnalysisRef: string | null = '';
  public AnalysisRef2: string | null = '';
  public AnalysisRef3: string | null = '';
  public AnalysisRef4: string | null = '';
  public AnalysisRef5: string | null = '';
  public SCAR: string | null = '';
  public Approve1Date: string | null = '';
  public Approve2Date: string | null = '';
  public Approve3Date: string | null = '';
  public InterimAction: string | null = '';
  public RootCause: string | null = '';
  public CPA: string | null = '';
  public CPASign: string | null = '';
  public CPADate: string | null = '';
  public CPAFollowUP: string | null = '';
  public CompleteDateCA: string | null = '';
  public RptCloseCA: boolean = false;
  public Approve1CA: string | null = '';
  public Approve1DateCA: string | null = '';
  public Approve2CA: string | null = '';
  public Approve2DateCA: string | null = '';
  public Approve3CA: string | null = '';
  public Approve3DateCA: string | null = '';
  public ReturnCategory: string | null = '';
  public ExternalReject: boolean = false;
  public InternalReject: boolean = false;
  public DidNotFollowProcedure: boolean = false;
  public ProcessChange: boolean = false;
  public MISC: boolean = false;
  public OpportunityforImprovement: boolean = false;
  public SCARIssued: boolean = false;
  public InternalAuditFinding: boolean = false;
  public SerialNumber: string | null = '';
  public MagniDetroitCR: string | null = '';
  public ResearchProject: string | null = '';
  public EstimatedCostofQuality: string | null = '';
  public Department: string | null = '';
  public CustomerFeedbackCategory: string | null = '';
  public CustomerComplaint: string | null = '';
  public AnalyticalReport: string | null = '';
  public AuditFinding: boolean = false;
  public ProposedAuditDate: string | null = '';
  public DidAssigneeCompleteSatisfactorily: boolean = false;
  public MeasurableFinding: boolean = false;
  public Level1: boolean = false;
  public Level2: boolean = false;
  public Level3: boolean = false;
  public ContinuousImprovement: boolean = false;
  public LastUpdate: string | null = '';
  public Visc: string | null = '';
  public WPG: string | null = '';
  public WtSlds: string | null = '';
  public Color: string | null = '';
  public ReclaimfromToteReturn: boolean = false;
  public Sales: string | null = '';
  public ResearchSupported: boolean = false;
  public Quarantine: boolean = false;
}

export interface Column {
  name: string;
  header: string;
  defaultFlex: number;
  headerProps: {
    style: {
      backgroundColor: string;
      color: string;
    };
    // Add other properties as needed
  };
}

export interface reportErrors {
  //CRid: boolean;
  DateCreated: boolean;
  Name: boolean;
  Product: boolean;
  //Lot: boolean;
  //Rma: boolean;
  AssignedTo: boolean;
  //CompleteDate: boolean;
  //Rpt: boolean;
  //Approval1: boolean;
  //Approval2: boolean;
  //Approval3: boolean;
  //CANeeded: boolean;
  //Complaint: boolean;
  Desc: boolean;
  CFdesc: boolean;
  CompanyName: boolean;
  Initiator: boolean;
  Level: boolean;
  Basis: boolean;
  /*
  QAAuthSign: boolean;
  NoDrums: boolean;
  NoTotes: boolean;
  DrumGal: boolean;
  ToteGal: boolean;
  DrumGWeigh: boolean;
  ToteGWeigh: boolean;
  RTnCredit: boolean;
  RtnEval: boolean;
  MatlRemix: boolean;
  RestockChg: boolean;
  AuthRtnSign: boolean;
  AcknRtnBy: boolean;
  RtnMComments: boolean;
  RtnMCommentsSign: boolean;
  */
  ActAssignDate: boolean;
  /*
  AnalysisNM: boolean;
  Rework: boolean;
  Repair: boolean;
  Scrap: boolean;
  UseAsIs: boolean;
  Reject: boolean;
  Escalate: boolean;
  InstDisp: boolean;
  FinalInsp: boolean;
  TrendCode: boolean;
  TrendCode2: boolean;
  TrendCode3: boolean;
  TrendCode4: boolean;
  TrendCode5: boolean;
  TrendCode5b: boolean;
  TrendCode6: boolean;
  EAPAComments: boolean;
  EAPA: boolean;
  AnalysisRef: boolean;
  AnalysisRef2: boolean;
  AnalysisRef3: boolean;
  AnalysisRef4: boolean;
  AnalysisRef5: boolean;
  SCAR: boolean;
  Approve1Date: boolean;
  Approve2Date: boolean;
  Approve3Date: boolean;
  InterimAction: boolean;
  RootCause: boolean;
  CPA: boolean;
  CPASign: boolean;
  CPADate: boolean;
  CPAFollowUP: boolean;
  CompleteDateCA: boolean;
  RptCloseCA: boolean;
  Approve1CA: boolean;
  Approve1DateCA: boolean;
  Approve2CA: boolean;
  Approve2DateCA: boolean;
  Approve3CA: boolean;
  Approve3DateCA: boolean;
  ReturnCategory: boolean;
  ExternalReject: boolean;
  InternalReject: boolean;
  DidNotFollowProcedure: boolean;
  ProcessChange: boolean;
  MISC: boolean;
  OpportunityforImprovement: boolean;
  SCARIssued: boolean;
  InternalAuditFinding: boolean;
  SerialNumber: boolean;
  MagniDetroitCR: boolean;
  ResearchProject: boolean;
  EstimatedCostofQuality: boolean;
  Department: boolean;
  CustomerFeedbackCategory: boolean;
  CustomerComplaint: boolean;
  AnalyticalReport: boolean;
  AuditFinding: boolean;
  ProposedAuditDate: boolean;
  DidAssigneeCompleteSatisfactorily: boolean;
  MeasurableFinding: boolean;
  Level1: boolean;
  Level2: boolean;
  Level3: boolean;
  ContinuousImprovement: boolean;
  LastUpdate: boolean;
  Visc: boolean;
  WPG: boolean;
  WtSlds: boolean;
  Color: boolean;
  ReclaimfromToteReturn: boolean;
  Sales: boolean;
  ResearchSupported: boolean;
  Quarantine: boolean;
  SSMA_TimeStamp: boolean;
  */
}






export interface KeyPairType {
  Username: string;
  UserID: number;
}

export interface UserObject {
  UserID: number;
  UserName: string;
  FirstName: string;
  LastName: string;
  Email: string;
  ApprovalID: number;
  ApprovalType: string;
  UserRole: string;
}





export class UserType {
  public UserID: number = 0;
  public UserName: string = "";
}

export class ApplicatorInfo {
  public CompanyId: number = 0;
  public CompanyName: string = "";
  public StreetAddress: string = "";
  public City: string = "";
  public State: string = "";
  public ZipCode: string = "";
}

export interface ProductInfo {
  SampleId: number;
  ProductId: number;
  Qty: number;
  UOM: string;
  Billable: number;

}

export class SampleHeader {
  public SampleId = 0;
  public PurposeOfRequest = '';
  public Initiator = 0;
  public ApplicatorId = 0;
  public Attention = '';
  public ShipNotes = '';
  public Status = 0;
  public Comments = '';
  public DeacomOrder = '';
  public RequiredDate = '';
  public ertRequired = true;
  public CreatedBy = 1;
  public AirShipped = true;
  public AnticipatedDate = '';
  public SalesManager = 0;
  public Tech = 0;
  public SalesReport = '';
  public TechReport = '';
}

export interface ErrorState {
  ProductId: boolean;
  Qty: boolean;
  UOM: boolean;
}



export interface SampleDetail {
  Product: string,
  Qty: number,
  UOM: string,
  Billable: boolean
}

export interface ErrorStateMain {
  PurposeOfRequest: boolean;
  Initiator: boolean;
  ApplicatorId: boolean;
  ShipNotes: boolean;
  RequiredDate: boolean;
  CreatedBy: boolean;
  AnticipatedDate: boolean;
  SalesManager: boolean;
  Tech: boolean;
}


export interface User {
  UserFirstName: string,
  UserLastName: string,
  UserTitle: string,
  UserDept: string,
  UserAddress: string,
  UserPhone: string,
  UserMobile: string,
  UserEmailAddress: string,
  UserRoleID: number,
  UserTypeID: number,
  CustomerID: number,
  Username: string,
  AccountCreationDate: string,
  ManagerID: number,
}


export interface FormErrorCheck {
  UserFirstName: boolean,
  UserLastName: boolean,
  UserTitle: boolean,
  UserDept: boolean,
  UserAddress: boolean,
  UserPhone: boolean,
  UserMobile: boolean,
  UserEmailAddress: boolean,
  UserRoleID: boolean,
  UserTypeID: boolean,
  Username: boolean,
  ManagerID: boolean,
}



export interface KeyPairValues {
  type: string;
  typeId: string;
}

export interface CommentDataStuct {
  Comment: string;
  CommentBy: number;
  CommentDate: Date;
  CommentTypeID: string;
  EntityId: number;
  //IsPrivate:boolean ;
}



export interface Filetoupload {
  fileName: Blob,
  newFileName: string,
  fileDescription: string,
}

export const renderDate = (props: any, { index }: { index: any }) => {
  const dateName = props.cellProps.computedHeader.toString().split(' ')[0];
  const i = index == 1 ? 2 : 1;
  const filterName = props.filterValue.operator.toString();
  const firstResult = filterName.replace(/([A-Z])/g, " $1");
  const finalResult = firstResult.charAt(0).toUpperCase() + firstResult.slice(1);
  return {
    dateFormat: 'MM-DD-YYYY',
    cancelButton: false,
    highlightWeekends: false,
    placeholder: index == 1 ? dateName + ' date is before...' : dateName + ' ' + (finalResult === 'Neq' ? 'Not Equal' : finalResult) + '...',
  }

}

export interface CheckboxProps {
  id: string;
  name: string;
  label: string;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export interface CheckboxGroupProps {
  checkboxes: CheckboxProps[];
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
}

interface CheckboxOption {
  id: string;
  name: string;
  label: string;
}
