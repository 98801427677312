import React from "react";
import { useState, useEffect } from 'react';
import { Input, Button, Label, FormGroup, ModalHeader, Modal, ModalBody, ModalFooter, Row, Col, Table } from "reactstrap";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Menu from './Menu';
import Header2 from './Header2';
import { BounceLoader } from "react-spinners";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router";
import moment from "moment";
import { KeyPairType, ReportObject, UserObject, reportErrors, UserType } from './MagniTypes';
import APICalls from "./APICalls";

function AddCR() {
    const dateRef = React.useRef<HTMLInputElement>(null);
    const nav = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    //const [taskTypeoptions, setOptions] = useState([]);
    //const [priorityoptions, setOptions1] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    //const [taskCategoryoptions, setOptions3] = useState([]);
    const [user, setUser] = useState<UserType>(new UserType());
    const [productoptions, setProductOptions] = useState([]);
    const [assignedToUsers, setAssignedToUsers] = useState([]);
    //const [systemoptions, setOptions5] = useState([]);
    //const [managerOptions, setManagerOptions] = useState<KeyPairType[]>([]);
    //const [assignUsers, setAssignUsers] = useState([]);
    const [pageLoading, setPageLoading] = useState(true);
    //const [nextUpdate, setNextUpdate] = useState([]);
    const [initiatorOptions, setInitiatorOptions] = useState([]);
    //const navigate = useNavigate();
    const apiCalls = new APICalls();




    const initialFormData: ReportObject = {
        CRid: 0,
        DateCreated: moment().format('YYYY-MM-DD'),
        Name: '',
        Product: '',
        Lot: 0,
        Rma: false,
        AssignedTo: '',
        CompleteDate: null,
        Rpt: false,
        Approval1: null,
        Approval2: null,
        Approval3: null,
        CANeeded: 0,
        Complaint: false,
        Desc: '',
        CFdesc: '',
        CompanyName: '',
        Initiator: '',
        Level: '',
        Basis: '',
        QAAuthSign: null,
        NoDrums: null,
        NoTotes: null,
        DrumGal: 0,
        ToteGal: 0,
        DrumGWeigh: 0,
        ToteGWeigh: 0,
        RTnCredit: false,
        RtnEval: false,
        MatlRemix: false,
        RestockChg: null,
        AuthRtnSign: null,
        AcknRtnBy: null,
        RtnMComments: null,
        RtnMCommentsSign: null,
        ActAssignDate: moment().format('YYYY-MM-DD'),
        AnalysisNM: null,
        Rework: false,
        Repair: false,
        Scrap: false,
        UseAsIs: false,
        Reject: false,
        Escalate: false,
        InstDisp: null,
        FinalInsp: null,
        TrendCode: null,
        TrendCode2: null,
        TrendCode3: null,
        TrendCode4: null,
        TrendCode5: null,
        TrendCode5b: null,
        TrendCode6: null,
        EAPAComments: null,
        EAPA: false,
        AnalysisRef: null,
        AnalysisRef2: null,
        AnalysisRef3: null,
        AnalysisRef4: null,
        AnalysisRef5: null,
        SCAR: null,
        Approve1Date: null,
        Approve2Date: null,
        Approve3Date: null,
        InterimAction: null,
        RootCause: null,
        CPA: null,
        CPASign: null,
        CPADate: null,
        CPAFollowUP: null,
        CompleteDateCA: null,
        RptCloseCA: false,
        Approve1CA: null,
        Approve1DateCA: null,
        Approve2CA: null,
        Approve2DateCA: null,
        Approve3CA: null,
        Approve3DateCA: null,
        ReturnCategory: null,
        ExternalReject: false,
        InternalReject: false,
        DidNotFollowProcedure: false,
        ProcessChange: false,
        MISC: false,
        OpportunityforImprovement: false,
        SCARIssued: false,
        InternalAuditFinding: false,
        SerialNumber: null,
        MagniDetroitCR: null,
        ResearchProject: null,
        EstimatedCostofQuality: null,
        Department: null,
        CustomerFeedbackCategory: null,
        CustomerComplaint: null,
        AnalyticalReport: null,
        AuditFinding: false,
        ProposedAuditDate: null,
        DidAssigneeCompleteSatisfactorily: false,
        MeasurableFinding: false,
        Level1: false,
        Level2: false,
        Level3: false,
        ContinuousImprovement: false,
        LastUpdate: null,
        Visc: null,
        WPG: null,
        WtSlds: null,
        Color: null,
        ReclaimfromToteReturn: false,
        Sales: null,
        ResearchSupported: false,
        Quarantine: false,
    };



    const [reportErrors, setReportErrors] = useState<reportErrors>({
        //CRid: false,
        DateCreated: false,
        Name: false,
        Product: false,
        //Lot: false,
        //Rma: false,
        AssignedTo: false,
        //CompleteDate: false,
        //Rpt: false,
        //Approval1: false,
        //Approval2: false,
        //Approval3: false,
        //CANeeded: false,
        //Complaint: false,
        Desc: false,
        CFdesc: false,
        CompanyName: false,
        Initiator: false,
        Level: false,
        Basis: false,
        /*
        QAAuthSign: false,
        NoDrums: false,
        NoTotes: false,
        DrumGal: false,
        ToteGal: false,
        DrumGWeigh: false,
        ToteGWeigh: false,
        RTnCredit: false,
        RtnEval: false,
        MatlRemix: false,
        RestockChg: false,
        AuthRtnSign: false,
        AcknRtnBy: false,
        RtnMComments: false,
        RtnMCommentsSign: false,
        */
        ActAssignDate: false,
        /*
        AnalysisNM: false,
        Rework: false,
        Repair: false,
        Scrap: false,
        UseAsIs: false,
        Reject: false,
        Escalate: false,
        InstDisp: false,
        FinalInsp: false,
        TrendCode: false,
        TrendCode2: false,
        TrendCode3: false,
        TrendCode4: false,
        TrendCode5: false,
        TrendCode5b: false,
        TrendCode6: false,
        EAPAComments: false,
        EAPA: false,
        AnalysisRef: false,
        AnalysisRef2: false,
        AnalysisRef3: false,
        AnalysisRef4: false,
        AnalysisRef5: false,
        SCAR: false,
        Approve1Date: false,
        Approve2Date: false,
        Approve3Date: false,
        InterimAction: false,
        RootCause: false,
        CPA: false,
        CPASign: false,
        CPADate: false,
        CPAFollowUP: false,
        CompleteDateCA: false,
        RptCloseCA: false,
        Approve1CA: false,
        Approve1DateCA: false,
        Approve2CA: false,
        Approve2DateCA: false,
        Approve3CA: false,
        Approve3DateCA: false,
        ReturnCategory: false,
        ExternalReject: false,
        InternalReject: false,
        DidNotFollowProcedure: false,
        ProcessChange: false,
        MISC: false,
        OpportunityforImprovement: false,
        SCARIssued: false,
        InternalAuditFinding: false,
        SerialNumber: false,
        MagniDetroitCR: false,
        ResearchProject: false,
        EstimatedCostofQuality: false,
        Department: false,
        CustomerFeedbackCategory: false,
        CustomerComplaint: false,
        AnalyticalReport: false,
        AuditFinding: false,
        ProposedAuditDate: false,
        DidAssigneeCompleteSatisfactorily: false,
        MeasurableFinding: false,
        Level1: false,
        Level2: false,
        Level3: false,
        ContinuousImprovement: false,
        LastUpdate: false,
        Visc: false,
        WPG: false,
        WtSlds: false,
        Color: false,
        ReclaimfromToteReturn: false,ateddate
        Sales: false,
        ResearchSupported: false,
        Quarantine: false,
        SSMA_TimeStamp: false,
        */
    });


    const [formData, setFormData] = useState<ReportObject>(initialFormData);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    const setInit = async () => {
        const userobj: any = JSON.parse(localStorage.getItem('user') as string);
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          Createdby: userobj ? userobj.UserID : 0, 
        }));
      }


    useEffect(() => {

        const fetchData = async () => {
            setTimeout(() => {
                setPageLoading(false);
            }, 1500);
            await GetInitiators();
            await setInit();
            await GetProducts();
            await GetAssignedToUsers();
            await GetCustomers();
            setUser(JSON.parse(localStorage.getItem('user') || '{}'));
            setFormData({ ...formData, Initiator: String((user === null ? 0 : user.UserID) || 0) })
            const handleResize = () => {
                setIsMobile(window.innerWidth <= 768);
                setWindowWidth(window.innerWidth);
            };
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };


        }

        fetchData();

    }, []);


    const HandleSubmit = async (e: any) => {
        e.preventDefault();

        let errors: reportErrors = {
            //CRid: formData.CRid === 0 ? true : false,
            DateCreated: formData.DateCreated === null ? true : false,
            Name: formData.Name === '' ? true : false,
            Product: formData.Product === '' ? true : false,
            //Lot: formData.Lot === 0 ? true : false,
            //Rma: formData.Rma === false ? true : false,
            AssignedTo: formData.AssignedTo === '' ? true : false,
            //CompleteDate: formData.CompleteDate === '' ? true : false,
            //Rpt: formData.Rpt === false ? true : false,
            //Approval1: formData.Approval1 === '' ? true : false,
            //Approval2: formData.Approval2 === '' ? true : false,
            //Approval3: formData.Approval3 === '' ? true : false,
            //CANeeded: formData.CANeeded === 0 ? true : false,
            //Complaint: formData.Complaint === false ? true : false,
            Desc: formData.Desc === '' ? true : false,
            CFdesc: formData.CFdesc === '' ? true : false,
            CompanyName: formData.CompanyName === '' ? true : false,
            Initiator: formData.Initiator === '' ? true : false,
            Level: formData.Level === '' ? true : false,
            Basis: formData.Basis === '' ? true : false,
            /*
            QAAuthSign: formData.QAAuthSign === '' ? true : false,
            NoDrums: formData.NoDrums === '' ? true : false,
            NoTotes: formData.NoTotes === '' ? true : false,
            DrumGal: formData.DrumGal === 0 ? true : false,
            ToteGal: formData.ToteGal === 0 ? true : false,
            DrumGWeigh: formData.DrumGWeigh === 0 ? true : false,
            ToteGWeigh: formData.ToteGWeigh === 0 ? true : false,
            RTnCredit: formData.RTnCredit === false ? true : false,
            RtnEval: formData.RtnEval === false ? true : false,
            MatlRemix: formData.MatlRemix === false ? true : false,
            RestockChg: formData.RestockChg === '' ? true : false,
            AuthRtnSign: formData.AuthRtnSign === '' ? true : false,
            AcknRtnBy: formData.AcknRtnBy === '' ? true : false,
            RtnMComments: formData.RtnMComments === '' ? true : false,
            RtnMCommentsSign: formData.RtnMCommentsSign === '' ? true : false,
            */
            ActAssignDate: formData.ActAssignDate === null ? true : false,
            /*
            AnalysisNM: formData.AnalysisNM === '' ? true : false,
            Rework: formData.Rework === false ? true : false,
            Repair: formData.Repair === false ? true : false,
            Scrap: formData.Scrap === false ? true : false,
            UseAsIs: formData.UseAsIs === false ? true : false,
            Reject: formData.Reject === false ? true : false,
            Escalate: formData.Escalate === false ? true : false,
            InstDisp: formData.InstDisp === '' ? true : false,
            FinalInsp: formData.FinalInsp === '' ? true : false,
            TrendCode: formData.TrendCode === '' ? true : false,
            TrendCode2: formData.TrendCode2 === '' ? true : false,
            TrendCode3: formData.TrendCode3 === '' ? true : false,
            TrendCode4: formData.TrendCode4 === '' ? true : false,
            TrendCode5: formData.TrendCode5 === '' ? true : false,
            TrendCode5b: formData.TrendCode5b === '' ? true : false,
            TrendCode6: formData.TrendCode6 === '' ? true : false,
            EAPAComments: formData.EAPAComments === '' ? true : false,
            EAPA: formData.EAPA === false ? true : false,
            AnalysisRef: formData.AnalysisRef === '' ? true : false,
            AnalysisRef2: formData.AnalysisRef2 === '' ? true : false,
            AnalysisRef3: formData.AnalysisRef3 === '' ? true : false,
            AnalysisRef4: formData.AnalysisRef4 === '' ? true : false,
            AnalysisRef5: formData.AnalysisRef5 === '' ? true : false,
            SCAR: formData.SCAR === '' ? true : false,
            Approve1Date: formData.Approve1Date === '' ? true : false,
            Approve2Date: formData.Approve2Date === '' ? true : false,
            Approve3Date: formData.Approve3Date === '' ? true : false,
            InterimAction: formData.InterimAction === '' ? true : false,
            RootCause: formData.RootCause === '' ? true : false,
            CPA: formData.CPA === '' ? true : false,
            CPASign: formData.CPASign === '' ? true : false,
            CPADate: formData.CPADate === '' ? true : false,
            CPAFollowUP: formData.CPAFollowUP === '' ? true : false,
            CompleteDateCA: formData.CompleteDateCA === '' ? true : false,
            RptCloseCA: formData.RptCloseCA === false ? true : false,
            Approve1CA: formData.Approve1CA === '' ? true : false,
            Approve1DateCA: formData.Approve1DateCA === '' ? true : false,
            Approve2CA: formData.Approve2CA === '' ? true : false,
            Approve2DateCA: formData.Approve2DateCA === '' ? true : false,
            Approve3CA: formData.Approve3CA === '' ? true : false,
            Approve3DateCA: formData.Approve3DateCA === '' ? true : false,
            ReturnCategory: formData.ReturnCategory === '' ? true : false,
            ExternalReject: formData.ExternalReject === false ? true : false,
            InternalReject: formData.InternalReject === false ? true : false,
            DidNotFollowProcedure: formData.DidNotFollowProcedure === false ? true : false,
            ProcessChange: formData.ProcessChange === false ? true : false,
            MISC: formData.MISC === false ? true : false,
            OpportunityforImprovement: formData.OpportunityforImprovement === false ? true : false,
            SCARIssued: formData.SCARIssued === false ? true : false,
            InternalAuditFinding: formData.InternalAuditFinding === false ? true : false,
            SerialNumber: formData.SerialNumber === '' ? true : false,
            MagniDetroitCR: formData.MagniDetroitCR === '' ? true : false,
            ResearchProject: formData.ResearchProject === '' ? true : false,
            EstimatedCostofQuality: formData.EstimatedCostofQuality === '' ? true : false,
            Department: formData.Department === '' ? true : false,
            CustomerFeedbackCategory: formData.CustomerFeedbackCategory === '' ? true : false,
            CustomerComplaint: formData.CustomerComplaint === '' ? true : false,
            AnalyticalReport: formData.AnalyticalReport === '' ? true : false,
            AuditFinding: formData.AuditFinding === false ? true : false,
            ProposedAuditDate: formData.ProposedAuditDate === '' ? true : false,
            DidAssigneeCompleteSatisfactorily: formData.DidAssigneeCompleteSatisfactorily === false ? true : false,
            MeasurableFinding: formData.MeasurableFinding === false ? true : false,
            Level1: formData.Level1 === false ? true : false,
            Level2: formData.Level2 === false ? true : false,
            Level3: formData.Level3 === false ? true : false,
            ContinuousImprovement: formData.ContinuousImprovement === false ? true : false,
            LastUpdate: formData.LastUpdate === '' ? true : false,
            Visc: formData.Visc === '' ? true : false,
            WPG: formData.WPG === '' ? true : false,
            WtSlds: formData.WtSlds === '' ? true : false,
            Color: formData.Color === '' ? true : false,
            ReclaimfromToteReturn: formData.ReclaimfromToteReturn === false ? true : false,
            Sales: formData.Sales === '' ? true : false,
            ResearchSupported: formData.ResearchSupported === false ? true : false,
            Quarantine: formData.Quarantine === false ? true : false,
            SSMA_TimeStamp: formData.SSMA_TimeStamp === '' ? true : false,
            */
        };

        setReportErrors(errors);
        console.log(errors)
        console.log(formData)




        try {
            if (!Object.values(errors).some(field => field === true)) {
                const response = await apiCalls.AddReport(formData);
                console.log(response);
                if (response) {
                    toast.success("Successfully Added Report\n Redirecting to All Reports");

                    setTimeout(() => {
                        nav('/conditionreports/');
                    }, 5000);

                } else {
                    toast.error("Failed to add task");
                }
            }
            else {
                toast.error("Please fill all the required fields that are highlighted in red");
            }
        }
        catch (error) {
            console.error('Failed to add:', error);
        }
    }


    const [isDisabled, setIsDisabled] = useState(false);

    const handleChange = (event: any) => {
        const { name, value, type, checked } = event.target;
        //console.log(value)
        const updatedValue = type === 'checkbox' ? (checked ? 1 : 0) : value.trim() === '' ? null : value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: updatedValue,
        }));
    }


    const GetProducts = async () => {
        //console.log(formData);
        const response = await apiCalls.GetProducts();
        const data = response.data;
        setProductOptions(data);
        console.log(productoptions);
    }

    const GetInitiators = async () => {
        //console.log(formData);
        const response = await apiCalls.GetInitiators();
        const data = response.data;
        setInitiatorOptions(data);
        console.log(initiatorOptions);
        const userID = user ? user.UserID : 0;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            Initiator: user ? userID : 0
        }))
    }


    const GetAssignedToUsers = async () => {
        //console.log(formData);
        const response = await apiCalls.GetAssignedToUsers();
        const data = response.data;
        setAssignedToUsers(data);
        console.log(assignedToUsers);
    }

    const GetCustomers = async () => {
        try {
            const response = await apiCalls.GetCustomers(); // Wait for the Promise to resolve
            const data = response.data;
            setCustomerOptions(data);
            console.log(customerOptions);
        } catch (error) {
            console.error('Error fetching customers:', error);
        }
    };





    const redirectToConditionReports = () => {
        window.location.href = '/conditionreports/';
    };





    const containerStyle = {
        display: 'flex',
        justifyContent: '',
        //alignItems: 'center',
    };



    const textStyle = {
        //borderRadius: "5px",
        //padding: '0px',
        //justifyContent: 'center',
        //alignItems: 'center',
        width: '50%'

    };

    const dateStyle = {
        borderRadius: "5px",
        padding: '5px',
        //justifyContent: 'center'
        //alignItems: 'center'


    };

    const textareaStyle = {
        //borderRadius: "5px",
        //padding: '5px',
        //justifyContent: 'center'
        //alignItems: 'center'


    };


    const dropDownStyle = {
        borderRadius: "5px",
        padding: '5px',
        borderColor: 'black',
        border: '2px'
    }



    return (

        <div className="container-fluid class1" style={{ backgroundColor: '#BAEBE1', paddingLeft: 0, paddingRight: 0 }}>


            <Header2 />
            {pageLoading ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <BounceLoader color={"#123abc"} loading={true} size={100} />
                </div>
                :

                <div className="" style={{ backgroundColor: "#BAEBE1", margin: 0 }}>
                    <div className="row row-cols-1 row-cols-md-1 g-4" style={{ backgroundColor: "#BAEBE1", }}>
                        <div className="col" style={{
                            backgroundColor: '#BAEBE1'
                        }}>
                            <div className="card" style={{
                                backgroundColor: '#BAEBE1'
                            }}>
                                <Button color="primary" onClick={() => nav('/conditionreports/')} style={{ backgroundColor: '#025444', fontSize: '18px', padding: '5px 10px', margin: '0 auto', display: 'block', marginTop: '25px', marginBottom: '0px' }}>Return to Condition Reports</Button>
                                <div className="card-body" style={{ backgroundColor: "#BAEBE1", }}>
                                    <div className="container text-center response-text" style={{ marginBottom: 0, backgroundColor: "#BAEBE1", }}>
                                        <div className="mt-3" style={{ backgroundColor: "#BAEBE1", }} >

                                            <form onSubmit={HandleSubmit} style={{ backgroundColor: "#BAEBE1", }}>
                                                <ModalBody style={{ paddingLeft: '20px', maxHeight: '90%', backgroundColor: "#BAEBE1", }}>
                                                    <h5 style={{ textAlign: "center", fontSize: 30 }}>  Create Report </h5>
                                                    <h5 style={{ textAlign: "center", fontSize: 24 }}>  Initiator </h5>




                                                    <Row className="mx-auto justify-content-center align-items-center" style={{ marginBottom: '25px', }}>
                                                        <Col sm={3}>
                                                            <Label for="exampleEmail" style={{ marginLeft: '-190px' }}>Report Name *</Label>
                                                            <Input
                                                                id="Name"
                                                                name="Name"
                                                                value={formData.Name}
                                                                onChange={handleChange}
                                                                style={reportErrors.Name ? { ...textStyle, border: "2px solid red", width: '200px', } : { ...textStyle, width: '200px', }}
                                                                type="text"
                                                            />
                                                        </Col>
                                                        <Col sm={3} style={{ marginLeft: '0px' }}>
                                                            <Label for="exampleEmail">Non Conformance</Label>
                                                            <div>
                                                                <input
                                                                    id="Level1"
                                                                    name="Level1"
                                                                    type="checkbox"
                                                                    checked={formData.Level1}
                                                                    onChange={handleChange}
                                                                    style={{}}
                                                                />
                                                                <label htmlFor="Level1">Level 1</label>

                                                                <input
                                                                    id="Level2"
                                                                    name="Level2"
                                                                    type="checkbox"
                                                                    checked={formData.Level2}
                                                                    onChange={handleChange}
                                                                    style={{ marginLeft: '30px' }}
                                                                />
                                                                <label htmlFor="Level2">Level 2</label>

                                                                <input
                                                                    id="Level3"
                                                                    name="Level3"
                                                                    type="checkbox"
                                                                    checked={formData.Level3}
                                                                    onChange={handleChange}
                                                                    style={{ marginLeft: '30px' }}
                                                                />
                                                                <label htmlFor="Level3">Level 3</label>
                                                            </div>
                                                        </Col>
                                                    </Row>







                                                    <Row style={{ marginLeft: '250px' }}>
                                                        <Col>

                                                            <FormGroup>
                                                                <Label style={{ marginTop: '38px', marginLeft: '100px' }}>
                                                                    Drums
                                                                </Label>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label style={{ marginBottom: '20px', marginLeft: '100px' }}>
                                                                    Pails
                                                                </Label>
                                                            </FormGroup>

                                                        </Col>
                                                        <Col>
                                                            <Label for="exampleEmail" sm={3} style={{ marginLeft: '-320px' }}>
                                                                #
                                                            </Label>
                                                            <Input
                                                                id="NoDrums"
                                                                type="text"
                                                                name="NoDrums"
                                                                value={formData.NoDrums === null ? '' : formData.NoDrums}
                                                                onChange={handleChange}
                                                                disabled={isDisabled}
                                                                style={{ ...textStyle, marginLeft: '-50px' }}
                                                            />
                                                            <Input
                                                                id="NoTotes"
                                                                type="text"
                                                                name="NoTotes"
                                                                value={formData.NoTotes === null ? '' : formData.NoTotes}
                                                                onChange={handleChange}
                                                                disabled={isDisabled}
                                                                style={{ ...textStyle, marginLeft: '-50px' }}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Label for="exampleEmail" sm={3} style={{ marginLeft: '-550px' }}>
                                                                Gal
                                                            </Label>
                                                            <Input
                                                                id="DrumGal"
                                                                type="text"
                                                                name="DrumGal"
                                                                value={formData.DrumGal}
                                                                onChange={handleChange}
                                                                disabled={isDisabled}
                                                                style={{ ...textStyle, marginLeft: '-180px' }}
                                                            />
                                                            <Input
                                                                id="ToteGal"
                                                                type="text"
                                                                name="ToteGal"
                                                                value={formData.ToteGal}
                                                                onChange={handleChange}
                                                                disabled={isDisabled}
                                                                style={{ ...textStyle, marginLeft: '-180px' }}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Label for="exampleEmail" sm={3} style={{ marginLeft: '-810px' }}>
                                                                lbs
                                                            </Label>
                                                            <Input
                                                                id="DrumGWeigh"
                                                                type="text"
                                                                name="DrumGWeigh"
                                                                value={formData.DrumGWeigh}
                                                                onChange={handleChange}
                                                                disabled={isDisabled}
                                                                style={{ ...textStyle, marginLeft: '-310px' }}
                                                            />
                                                            <Input
                                                                id="ToteGWeigh"
                                                                type="text"
                                                                name="ToteGWeigh"
                                                                value={formData.ToteGWeigh}
                                                                onChange={handleChange}
                                                                disabled={isDisabled}
                                                                style={{ ...textStyle, marginLeft: '-310px' }}
                                                            />
                                                        </Col>
                                                    </Row>







                                                    <Row className="justify-content-center" style={{}}>
                                                        <Col >
                                                            <div className="checkbox-group">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            id="ExternalReject"
                                                                            name="ExternalReject"
                                                                            checked={formData.ExternalReject}
                                                                            onChange={handleChange}
                                                                        />
                                                                        {' '}External Reject
                                                                    </Label>
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col >
                                                            <div className="checkbox-group">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            id="InternalReject"
                                                                            name="InternalReject"
                                                                            checked={formData.InternalReject}
                                                                            onChange={handleChange}
                                                                            disabled={isDisabled}
                                                                        />
                                                                        {' '}Internal Reject
                                                                    </Label>
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col >
                                                            <div className="checkbox-group">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            id="DidNotFollowProcedure"
                                                                            name="DidNotFollowProcedure"
                                                                            checked={formData.DidNotFollowProcedure}
                                                                            onChange={handleChange}
                                                                            disabled={isDisabled}
                                                                        />
                                                                        {' '}Did Not Follow Procedure
                                                                    </Label>
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col >
                                                            <div className="checkbox-group">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            id="AuditFinding"
                                                                            name="AuditFinding"
                                                                            checked={formData.AuditFinding}
                                                                            onChange={handleChange}
                                                                            disabled={isDisabled}
                                                                        />
                                                                        {' '}Audit Finding
                                                                    </Label>
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col >
                                                            <div className="checkbox-group">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            id="SCARIssued"
                                                                            name="SCARIssued"
                                                                            checked={formData.SCARIssued}
                                                                            onChange={handleChange}
                                                                            disabled={isDisabled}
                                                                        />
                                                                        {' '}SCAR Issued
                                                                    </Label>
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col >
                                                            <div className="checkbox-group">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            id="OpportunityforImprovement"
                                                                            name="OpportunityforImprovement"
                                                                            checked={formData.OpportunityforImprovement}
                                                                            onChange={handleChange}
                                                                            disabled={isDisabled}
                                                                        />
                                                                        {' '}Opportunity for Improvement
                                                                    </Label>
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col >
                                                            <div className="checkbox-group">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            id="InternalAuditFinding"
                                                                            name="InternalAuditFinding"
                                                                            checked={formData.InternalAuditFinding}
                                                                            onChange={handleChange}
                                                                            disabled={isDisabled}
                                                                        />
                                                                        {' '}Internal Audit Finding
                                                                    </Label>
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                    </Row>






                                                    <Row className="justify-content-center" style={{ marginTop: '20px' }}>
                                                        <Col>
                                                            <div className="checkbox-group">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            id="ProcessChange"
                                                                            name="ProcessChange"
                                                                            checked={formData.ProcessChange}
                                                                            onChange={handleChange}
                                                                            disabled={isDisabled}
                                                                        />
                                                                        {' '}Process Change
                                                                    </Label>
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="checkbox-group">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            id="MISC"
                                                                            name="MISC"
                                                                            checked={formData.MISC}
                                                                            onChange={handleChange}
                                                                            disabled={isDisabled}
                                                                        />
                                                                        {' '}MISC
                                                                    </Label>
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="checkbox-group">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            id="MeasurableFinding"
                                                                            name="MeasurableFinding"
                                                                            checked={formData.MeasurableFinding}
                                                                            onChange={handleChange}
                                                                            disabled={isDisabled}
                                                                        />
                                                                        {' '}Measurable Finding
                                                                    </Label>
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="checkbox-group">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            id="Quarantine"
                                                                            name="Quarantine"
                                                                            checked={formData.Quarantine}
                                                                            onChange={handleChange}
                                                                            disabled={isDisabled}
                                                                        />
                                                                        {' '}Quarantine
                                                                    </Label>
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="checkbox-group">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            id="ReclaimfromToteReturn"
                                                                            name="ReclaimfromToteReturn"
                                                                            checked={formData.ReclaimfromToteReturn}
                                                                            onChange={handleChange}
                                                                            disabled={isDisabled}
                                                                        />
                                                                        {' '}Reclaim from Tote Return
                                                                    </Label>
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="checkbox-group">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            id="ResearchSupported"
                                                                            name="ResearchSupported"
                                                                            checked={formData.ResearchSupported}
                                                                            onChange={handleChange}
                                                                            disabled={isDisabled}
                                                                        />
                                                                        {' '}Research Supported
                                                                    </Label>
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="checkbox-group">
                                                                <FormGroup check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        id="Complaint"
                                                                        name="Complaint"
                                                                        checked={formData.Complaint}
                                                                        onChange={handleChange}
                                                                        disabled={isDisabled}
                                                                    />
                                                                    {' '}Customer Complaint Submitted?
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                    </Row>






                                                    <Row className="mt-3 justify-content-center" style={{ marginTop: '20px' }}>
                                                        <Col sm={6}>
                                                            <FormGroup>
                                                                <Label for="Desc" style={{ marginLeft: '60px' }}>Description *</Label>
                                                                <Input
                                                                    id="Desc"
                                                                    type="textarea"
                                                                    name="Desc"
                                                                    value={formData.Desc}
                                                                    onChange={handleChange}
                                                                    disabled={isDisabled}
                                                                    style={reportErrors.Desc ? { ...textareaStyle, border: "2px solid red", width: '350px', height: '200px', marginLeft: '170px' } : { ...textareaStyle, width: '350px', height: '200px', marginLeft: '170px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm={6}>
                                                            <FormGroup>
                                                                <Label for="CFdesc" style={{ marginLeft: '-280px' }}>List the Possible Causes/Failure Mechanisms *</Label>
                                                                <Input
                                                                    id="CFdesc"
                                                                    type="textarea"
                                                                    name="CFdesc"
                                                                    value={formData.CFdesc}
                                                                    onChange={handleChange}
                                                                    disabled={isDisabled}
                                                                    style={reportErrors.CFdesc ? { ...textareaStyle, border: "2px solid red", width: '350px', height: '200px' } : { ...textareaStyle, width: '350px', height: '200px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>







                                                    <Row className="mt-3 justify-content-center" style={{ marginTop: '20px' }}>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <Label for="DateCreated" style={{ marginLeft: '-200px' }}>Date Created</Label>
                                                                <Input
                                                                    id="DateCreated"
                                                                    name="DateCreated"
                                                                    value={moment(formData.DateCreated).format('YYYY-MM-DD')}
                                                                    onChange={handleChange}
                                                                    type="date"
                                                                    disabled={true}
                                                                    style={reportErrors.DateCreated ? { ...dateStyle, border: "2px solid red", width: '150px' } : { ...dateStyle, width: '150px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <Label for="SerialNumber" style={{ marginLeft: '-140px' }}>Serial Number</Label>
                                                                <Input
                                                                    id="SerialNumber"
                                                                    name="SerialNumber"
                                                                    type="text"
                                                                    value={formData.SerialNumber === null ? '' : formData.SerialNumber}
                                                                    onChange={handleChange}
                                                                    disabled={isDisabled}
                                                                    style={{ ...textStyle, marginLeft: '25px', width: '200px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <Label for="Lot" style={{ marginLeft: '-130px' }}>Lot Number</Label>
                                                                <Input
                                                                    id="Lot"
                                                                    name="Lot"
                                                                    value={formData.Lot}
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    disabled={isDisabled}
                                                                    style={{ ...textStyle, marginLeft: '40px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>


                                                    </Row>


                                                    <Row className="mt-3 justify-content-center" style={{ marginTop: '20px', marginLeft: '-50px' }}>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <Label for="CompanyName" style={{ marginLeft: '-180px' }}>Company Name *</Label>
                                                                <select
                                                                    value={formData.CompanyName}
                                                                    className="form-select"
                                                                    id="CompanyName"
                                                                    name="CompanyName"
                                                                    onChange={handleChange}
                                                                    style={reportErrors.CompanyName ? { ...dropDownStyle, border: "2px solid red", width: '300px', marginLeft: '0px' } : { ...dropDownStyle, width: '300px', marginLeft: '0px' }}
                                                                >
                                                                    <option value="">Select an option</option>
                                                                    {customerOptions.map((option: any, index) => (
                                                                        <option key={index} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <Label for="Product" style={{ marginLeft: '-130px' }}>Product ID *</Label>
                                                                <select
                                                                    value={formData.Product}
                                                                    className="form-select"
                                                                    id="Product"
                                                                    name="Product"
                                                                    onChange={handleChange}
                                                                    style={reportErrors.Product ? { ...dropDownStyle, border: "2px solid red", marginLeft: '50px', width: '200px' } : { ...dropDownStyle, marginLeft: '50px', width: '200px' }}
                                                                >
                                                                    <option value="">Select an option</option>
                                                                    <option value="NA">NA</option>
                                                                    {productoptions.map((option: any, index) => (
                                                                        <option key={index} value={option.typeId}>
                                                                            {option.type}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <Label for="Initiator" style={{ marginLeft: '-130px' }}>Initiator *</Label>
                                                                <Input
                                                                    value={user ? user.UserID : 'no user'}
                                                                    //value={formData.Initiator}
                                                                    //className="form-select"
                                                                    type="text"
                                                                    id="Initiator"
                                                                    name="Initiator"
                                                                    onChange={handleChange}
                                                                    disabled={true}
                                                                    style={reportErrors.Initiator ? { ...dropDownStyle, border: "2px solid red", marginLeft: '55px', width: '200px' } : { ...dropDownStyle, marginLeft: '55px', width: '200px' }}
                                                                >
                                                                    {/* <option value="">Select an option</option>
                                                                    {initiatorOptions.map((option, index) => (
                                                                        <option key={index} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))} */}
                                                                </Input>
                                                            </FormGroup>
                                                        </Col>


                                                    </Row>


                                                    <Row className="mt-3 justify-content-center" style={{ marginTop: '0px' }}>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <Label for="MagniDetroitCR" style={{ marginLeft: '-170px' }}>Magni Detroit CR</Label>
                                                                <Input
                                                                    id="MagniDetroitCR"
                                                                    name="MagniDetroitCR"
                                                                    type="text"
                                                                    value={formData.MagniDetroitCR === null ? '' : formData.MagniDetroitCR}
                                                                    onChange={handleChange}
                                                                    disabled={isDisabled}
                                                                    style={textStyle}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <Label for="AnalyticalReport" style={{ marginLeft: '-80px' }}>Analytical Report</Label>
                                                                <Input
                                                                    id="AnalyticalReport"
                                                                    name="AnalyticalReport"
                                                                    type="text"
                                                                    value={formData.AnalyticalReport === null ? '' : formData.AnalyticalReport}
                                                                    onChange={handleChange}
                                                                    disabled={isDisabled}
                                                                    style={{ ...textStyle, marginLeft: '45px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <Label for="Department" style={{ marginLeft: '-120px' }}>Department</Label>
                                                                <Input
                                                                    id="Department"
                                                                    name="Department"
                                                                    type="text"
                                                                    value={formData.Department === null ? '' : formData.Department}
                                                                    onChange={handleChange}
                                                                    disabled={isDisabled}
                                                                    style={{ ...textStyle, marginLeft: '40px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>




                                                    </Row>



                                                    <Row className="mt-3 justify-content-center" style={{ marginTop: '70px', marginBottom: '30px', }}>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <Label style={{ marginLeft: '10px' }}>Only required for External Reject or Customer Complaint:</Label>

                                                            </FormGroup>
                                                        </Col>



                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <Label for="CustomerFeedbackCategory" style={{ marginLeft: '-30px' }}>Customer Feedback Category</Label>
                                                                <select
                                                                    value={formData.CustomerFeedbackCategory === null ? '' : formData.CustomerFeedbackCategory}
                                                                    className="form-select"
                                                                    id="CustomerFeedbackCategory"
                                                                    name="CustomerFeedbackCategory"
                                                                    onChange={handleChange}
                                                                    disabled={isDisabled}
                                                                    style={{ ...dropDownStyle, marginLeft: '50px', width: '170px' }}
                                                                >
                                                                    <option value="">Select an option</option>
                                                                    <option value="Prod's performance, and other design features">Prod's performance, and other design features</option>
                                                                    <option value="Reliability and dependability of product">Reliability and dependability of product</option>
                                                                    <option value="Packaging">Packaging</option>
                                                                    <option value="Distribution and Delivery">Distribution and Delivery</option>
                                                                    <option value="Product Information">Product Information</option>
                                                                    <option value="Availability">Availability</option>
                                                                    <option value="Sales and Order Processing">Sales and Order Processing</option>
                                                                    <option value="After-Sales Support and Service">After-Sales Support and Service</option>
                                                                    <option value="Price">Price</option>
                                                                    <option value="Customer damaged at their site asked for repair">Customer damaged at their site asked for repair</option>

                                                                </select>
                                                            </FormGroup>
                                                        </Col>


                                                        <Col sm={3}>

                                                            <FormGroup>
                                                                <Label for="ReturnCategory" style={{ marginLeft: '-120px' }}>Return Category</Label>
                                                                <select
                                                                    className="form-select"
                                                                    id="ReturnCategory"
                                                                    name="ReturnCategory"
                                                                    value={formData.ReturnCategory === null ? '' : formData.ReturnCategory}
                                                                    onChange={handleChange}
                                                                    disabled={isDisabled}
                                                                    style={{ ...dropDownStyle, marginLeft: '30px', width: '170px' }}
                                                                >
                                                                    <option value="">Select an option</option>
                                                                    <option value="1">Unsatisfactory Product</option>
                                                                    <option value="2">Customer Accommodation</option>
                                                                    <option value="3">Obsolete Inventory</option>
                                                                    <option value="4">Completion of Trial</option>
                                                                    <option value="5">Customer Feedback</option>
                                                                    <option value="6">Customer Complaint</option>
                                                                </select>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <Label for="CustomerComplaint" style={{ marginLeft: '-250px' }}>Customer Complaint</Label>
                                                                <select
                                                                    value={formData.CustomerComplaint === null ? '' : formData.CustomerComplaint}
                                                                    className="form-select"
                                                                    id="CustomerComplaint"
                                                                    name="CustomerComplaint"
                                                                    onChange={handleChange}
                                                                    disabled={isDisabled}
                                                                    style={{ ...dropDownStyle, marginLeft: '-50px', width: '170px' }}
                                                                >

                                                                    <option value="">Select an option</option>
                                                                    <option value="Non conforming/ Unsatisfactory Product">Non conforming/ Unsatisfactory Product</option>
                                                                    <option value="Product Labeling Problem">Product Labeling Problem</option>
                                                                    <option value="Product Damaged in Delivery">Product Damaged in Delivery</option>
                                                                    <option value="Insufficient or Inadequate Quality Records">Insufficient or Inadequate Quality Records</option>
                                                                    <option value="Wrong Product or Quantity Shipped">Wrong Product or Quantity Shipped</option>
                                                                    <option value="Late Delivery to Customer">Late Delivery to Customer</option>
                                                                    <option value="Complaints Regarding Productivity">Complaints Regarding Productivity</option>
                                                                    <option value="Problems with Communication and Responsiveness">Problems with Communication and Responsiveness</option>
                                                                    <option value="Complaints Regarding Business Practices">Complaints Regarding Business Practices</option>
                                                                </select>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>














                                                    <h5 style={{ textAlign: "center", fontSize: 24, marginTop: '80px' }}>  Q.A. Manager </h5>


                                                    <Row className="mt-3" style={{ marginBottom: '25px' }}>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <Label for="Level" style={{ marginLeft: '-50px' }}>Level *</Label>
                                                                <select
                                                                    className="form-select"
                                                                    id="Level"
                                                                    name="Level"
                                                                    value={formData.Level}
                                                                    onChange={handleChange}
                                                                    disabled={isDisabled}
                                                                    style={reportErrors.Level ? { ...dropDownStyle, border: "2px solid red", width: '200px', marginLeft: '100px' } : { ...dropDownStyle, width: '200px', marginLeft: '100px' }}
                                                                >
                                                                    <option value="">Select an option</option>
                                                                    <option value="1">Level 1 - Multidisciplinary Corrective Action</option>
                                                                    <option value="2">Level 2 - Single Evaluator Corrective Action</option>
                                                                    <option value="3">Level 3 - Disposition Determination</option>
                                                                    <option value="4">Level 4 - Trend Determination</option>
                                                                    <option value="5">Level 5 - Customer Accommodation</option>
                                                                    <option value="6">Level 6 - unknown</option>
                                                                    <option value="7">S.C.A.R.</option>
                                                                </select>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <Label for="QAAuthSign" style={{ marginLeft: '00px' }}>Signature</Label>
                                                                <Input
                                                                    type="text"
                                                                    id="QAAuthSign"
                                                                    name="QAAuthSign"
                                                                    value={formData.QAAuthSign === null ? '' : formData.QAAuthSign}
                                                                    onChange={handleChange}
                                                                    disabled={isDisabled}
                                                                    style={{ ...textStyle, marginLeft: '110px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <Label for="Sales" style={{ marginLeft: '-170px' }}>Sales</Label>
                                                                <Input
                                                                    type="text"
                                                                    id="Sales"
                                                                    name="Sales"
                                                                    value={formData.Sales === null ? '' : formData.Sales}
                                                                    onChange={handleChange}
                                                                    disabled={isDisabled}
                                                                    style={{ ...textStyle, marginLeft: '40px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm={3}>
                                                            <FormGroup check style={{ marginLeft: '-150px' }}>
                                                                <Label check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        id="CANeeded"
                                                                        name="CANeeded"
                                                                        checked={formData.CANeeded === 1}
                                                                        onChange={handleChange}
                                                                        disabled={isDisabled}
                                                                    />
                                                                    {' '}Corrective Action Required?
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>




                                                    <Row className="mx-auto justify-content-center" style={{ marginTop: '20px' }}>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <Label for="Basis" style={{ marginTop: '20px', marginLeft: '-180px' }}>Comments *</Label>
                                                                <Input
                                                                    id="Basis"
                                                                    name="Basis"
                                                                    value={formData.Basis}
                                                                    onChange={handleChange}
                                                                    type="textarea"
                                                                    disabled={isDisabled}
                                                                    style={reportErrors.Basis ? { ...dropDownStyle, border: "2px solid red", height: '150px', width: '300px', marginTop: '28px', marginLeft: '-100px' } : { ...textareaStyle, height: '150px', width: '300px', marginTop: '28px', marginLeft: '-100px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <Label for="AnalysisNM" style={{ marginLeft: '100px' }}>Analysis of Nonconformance/Condition (Include results)</Label>
                                                                <Input
                                                                    id="AnalysisNM"
                                                                    name="AnalysisNM"
                                                                    value={formData.AnalysisNM === null ? '' : formData.AnalysisNM}
                                                                    onChange={handleChange}
                                                                    type="textarea"
                                                                    disabled={isDisabled}
                                                                    style={{ ...textareaStyle, marginLeft: '50px', height: '150px', width: '300px', }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>













                                                    <Row className="mx-auto justify-content-center" style={{ marginTop: '20px' }}>
                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <Label for="AssignedTo" style={{ marginLeft: '-150px' }}>Action Assigned To *</Label>
                                                                <select
                                                                    value={formData.AssignedTo}
                                                                    className="form-select"
                                                                    id="AssignedTo"
                                                                    name="AssignedTo"
                                                                    onChange={handleChange}
                                                                    style={reportErrors.AssignedTo ? { ...dropDownStyle, border: "2px solid red", width: '150px', marginLeft: '00px' } : { ...dropDownStyle, width: '150px', marginLeft: '00px' }}
                                                                >
                                                                    <option value="">Select an option</option>
                                                                    {assignedToUsers.map((option, index) => (
                                                                        <option key={index} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <Label for="ActAssignDate" style={{ marginLeft: '-180px' }}>Assigned Date *</Label>
                                                                <Input
                                                                    id="ActAssignDate"
                                                                    name="ActAssignDate"
                                                                    value={formData.ActAssignDate === null ? '' : moment(formData.ActAssignDate).format('YYYY-MM-DD')}
                                                                    onChange={handleChange}
                                                                    type="date"
                                                                    disabled={isDisabled}
                                                                    style={reportErrors.ActAssignDate ? { ...dateStyle, border: "2px solid red", width: '150px', } : { ...dateStyle, width: '150px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm={3}>
                                                            <FormGroup>
                                                                <Label for="ResearchProject" style={{ marginLeft: '-150px' }}>Research Project #</Label>
                                                                <Input
                                                                    id="ResearchProject"
                                                                    name="ResearchProject"
                                                                    value={formData.ResearchProject === null ? '' : formData.ResearchProject}
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    disabled={isDisabled}
                                                                    style={{ ...textStyle, }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>








                                                    <Row className="mx-auto justify-content-center" style={{ marginTop: '20px' }}>
                                                        <Col md={3}>
                                                            <FormGroup>
                                                                <Label for="Visc" style={{ marginLeft: '-150px' }}>Visc</Label>
                                                                <Input
                                                                    id="Visc"
                                                                    name="Visc"
                                                                    value={formData.Visc === null ? '' : formData.Visc}
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    disabled={isDisabled}
                                                                    style={{ ...textStyle, marginLeft: '50px' }}

                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md={3}>
                                                            <FormGroup>
                                                                <Label for="WPG" style={{ marginLeft: '-150px' }}>WPG</Label>
                                                                <Input
                                                                    id="WPG"
                                                                    name="WPG"
                                                                    value={formData.WPG === null ? '' : formData.WPG}
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    disabled={isDisabled}
                                                                    style={{ ...textStyle, marginLeft: '50px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md={3}>
                                                            <FormGroup>
                                                                <Label for="WtSlds" style={{ marginLeft: '-130px' }}>WT Slds</Label>
                                                                <Input
                                                                    id="WtSlds"
                                                                    name="WtSlds"
                                                                    value={formData.WtSlds === null ? '' : formData.WtSlds}
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    disabled={isDisabled}
                                                                    style={{ ...textStyle, marginLeft: '50px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md={3}>
                                                            <FormGroup>
                                                                <Label for="Color" style={{ marginLeft: '-150px' }}>Color</Label>
                                                                <Input
                                                                    id="Color"
                                                                    name="Color"
                                                                    value={formData.Color === null ? '' : formData.Color}
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    disabled={isDisabled}
                                                                    style={{ ...textStyle, marginLeft: '50px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
















                                                    <h5 style={{ textAlign: "center", fontSize: 24, marginTop: '100px', marginBottom: '30px' }}>  Evaluator </h5>

                                                    <Row className="justify-content-center">
                                                        <Col sm={1} className="mb-2">

                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        id="Rework"
                                                                        name="Rework"
                                                                        checked={formData.Rework}
                                                                        onChange={handleChange}
                                                                        disabled={isDisabled}
                                                                    />
                                                                    {' '}Rework
                                                                </Label>
                                                            </FormGroup>

                                                        </Col>

                                                        <Col sm={1} className="mb-2">

                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        id="Repair"
                                                                        name="Repair"
                                                                        checked={formData.Repair}
                                                                        onChange={handleChange}
                                                                        disabled={isDisabled}
                                                                    />
                                                                    {' '}Repair
                                                                </Label>
                                                            </FormGroup>

                                                        </Col>

                                                        <Col sm={1} className="mb-2">

                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        id="Scrap"
                                                                        name="Scrap"
                                                                        checked={formData.Scrap}
                                                                        onChange={handleChange}
                                                                        disabled={isDisabled}
                                                                    />
                                                                    {' '}Scrap
                                                                </Label>
                                                            </FormGroup>

                                                        </Col>

                                                        <Col sm={1} className="mb-2">

                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        id="UseAsIs"
                                                                        name="UseAsIs"
                                                                        checked={formData.UseAsIs}
                                                                        onChange={handleChange}
                                                                        disabled={isDisabled}
                                                                    />
                                                                    {' '}Use As Is
                                                                </Label>
                                                            </FormGroup>

                                                        </Col>

                                                        <Col sm={1} className="mb-2">
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        id="Reject"
                                                                        name="Reject"
                                                                        checked={formData.Reject}
                                                                        onChange={handleChange}
                                                                        disabled={isDisabled}
                                                                    />
                                                                    {' '}Reject
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm={1} className="mb-2">
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        id="Escalate"
                                                                        name="Escalate"
                                                                        checked={formData.Escalate}
                                                                        onChange={handleChange}
                                                                        disabled={isDisabled}
                                                                    />
                                                                    {' '}Escalate
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm={1} className="mb-2">
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        id="EAPA"
                                                                        name="EAPA"
                                                                        checked={formData.EAPA}
                                                                        onChange={handleChange}
                                                                        disabled={isDisabled}
                                                                    />
                                                                    {' '}EAPA
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>



                                                    <Row className="mx-auto" style={{ marginTop: '40px', }}>
                                                        <Col sm={3}>
                                                            <Label for="InstDisp" style={{ marginLeft: '20px', }}>Instruction for Disposition</Label>
                                                            <Input
                                                                id="InstDisp"
                                                                name="InstDisp"
                                                                value={formData.InstDisp === null ? '' : formData.InstDisp}
                                                                onChange={handleChange}
                                                                type="textarea"
                                                                disabled={isDisabled}
                                                                style={{ ...textareaStyle, width: '300px', height: '200px', marginTop: '10px' }}
                                                            />
                                                        </Col>
                                                        <Col sm={3}>
                                                            <Label for="FinalInsp" style={{ marginLeft: '90px', }}>Final Inspection / Conclusion</Label>
                                                            <Input
                                                                id="FinalInsp"
                                                                name="FinalInsp"
                                                                value={formData.FinalInsp === null ? '' : formData.FinalInsp}
                                                                onChange={handleChange}
                                                                type="textarea"
                                                                disabled={isDisabled}
                                                                style={{ ...textareaStyle, width: '300px', height: '200px', marginTop: '10px', marginLeft: '50px' }}
                                                            />
                                                        </Col>
                                                        <Col sm={3}>
                                                            <Label for="EstimatedCostofQuality" style={{ marginLeft: '80px', }}>Estimated Cost of Quality</Label>
                                                            <Input
                                                                id="EstimatedCostofQuality"
                                                                name="EstimatedCostofQuality"
                                                                value={formData.EstimatedCostofQuality === null ? '' : formData.EstimatedCostofQuality}
                                                                onChange={handleChange}
                                                                type="text"
                                                                disabled={isDisabled}
                                                                style={{ ...textStyle, marginBottom: '100px', verticalAlign: 'top', marginTop: '10px', marginLeft: '110px' }}
                                                            />
                                                        </Col>
                                                        <Col sm={3}>
                                                            <Label for="SCAR" style={{ marginLeft: '-110px', }}>SCAR</Label>
                                                            <Input
                                                                id="SCAR"
                                                                name="SCAR"
                                                                value={formData.SCAR === null ? '' : formData.SCAR}
                                                                onChange={handleChange}
                                                                type="text"
                                                                disabled={isDisabled}
                                                                style={{ ...textStyle, marginBottom: '100px', verticalAlign: 'top', marginTop: '10px', marginLeft: '20px' }}
                                                            />
                                                        </Col>
                                                    </Row>









                                                    <h5 style={{ textAlign: "center", fontSize: 24, marginTop: '100px', marginBottom: '50px' }}>  Report Status </h5>

                                                    <Row className="mx-auto justify-content-center">
                                                        <Col sm={2}>
                                                            <FormGroup>
                                                                <Label for="CompleteDate" style={{ marginLeft: '-135px' }}>Completion Date</Label>
                                                                <Input
                                                                    id="CompleteDate"
                                                                    name="CompleteDate"
                                                                    value={formData.CompleteDate === null ? '' : moment(formData.CompleteDate).format('YYYY-MM-DD')}
                                                                    onChange={handleChange}
                                                                    type="date"
                                                                    disabled={isDisabled}
                                                                    style={{ ...dateStyle, marginLeft: '-40px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm={2} style={{ marginLeft: '10px' }}>
                                                            <FormGroup>
                                                                <Label for="Approval1" style={{ marginLeft: '-130px' }}>QA Manager</Label>
                                                                <Input
                                                                    id="Approval1"
                                                                    name="Approval1"
                                                                    value={formData.Approval1 === null ? '' : formData.Approval1}
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    disabled={isDisabled}
                                                                    style={{ ...textStyle, marginLeft: '-20px', width: '180px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm={2} style={{ marginLeft: '10px' }}>
                                                            <FormGroup>
                                                                <Label for="Approval2" style={{ marginLeft: '-150px' }}>Assignee</Label>
                                                                <Input
                                                                    id="Approval2"
                                                                    name="Approval2"
                                                                    value={formData.Approval2 === null ? '' : formData.Approval2}
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    disabled={isDisabled}
                                                                    style={{ ...textStyle, marginLeft: '-20px', width: '180px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm={2} style={{ marginLeft: '10px' }}>
                                                            <FormGroup>
                                                                <Label for="Approval3" style={{ marginLeft: '-180px' }}>Other</Label>
                                                                <Input
                                                                    id="Approval3"
                                                                    name="Approval3"
                                                                    value={formData.Approval3 === null ? '' : formData.Approval3}
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    disabled={isDisabled}
                                                                    style={{ ...textStyle, marginLeft: '-20px', width: '180px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>





                                                    <Row className="mx-auto justify-content-center" style={{ marginBottom: '30px' }}>
                                                        <Col sm={2}>
                                                            <FormGroup>
                                                                <Label for="Rpt" style={{ marginLeft: '-80px' }}>Report Closed?   </Label>
                                                                <Input
                                                                    type="checkbox"
                                                                    id="Rpt"
                                                                    name="Rpt"
                                                                    checked={formData.Rpt}
                                                                    onChange={handleChange}
                                                                    disabled={isDisabled}
                                                                    style={{ marginLeft: '10px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={2} >
                                                            <FormGroup>
                                                                <Label for="Approve1Date" style={{ marginLeft: '-205px' }}>Date</Label>
                                                                <Input
                                                                    type="date"
                                                                    id="Approve1Date"
                                                                    name="Approve1Date"
                                                                    value={formData.Approve1Date === null ? '' : moment(formData.Approve1Date).format('YYYY-MM-DD')}
                                                                    onChange={handleChange}
                                                                    disabled={isDisabled}
                                                                    style={{ ...dateStyle, marginLeft: '-30px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={2} >
                                                            <FormGroup>
                                                                <Label for="Approve2Date" style={{ marginLeft: '-185px' }}>Date</Label>
                                                                <Input
                                                                    type="date"
                                                                    id="Approve2Date"
                                                                    name="Approve2Date"
                                                                    value={formData.Approve2Date === null ? '' : moment(formData.Approve2Date).format('YYYY-MM-DD')}
                                                                    onChange={handleChange}
                                                                    disabled={isDisabled}
                                                                    style={{ ...dateStyle, marginLeft: '-20px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <FormGroup>
                                                                <Label for="Approve3Date" style={{ marginLeft: '-160px' }}>Date</Label>
                                                                <Input
                                                                    type="date"
                                                                    id="Approve3Date"
                                                                    name="Approve3Date"
                                                                    value={formData.Approve3Date === null ? '' : moment(formData.Approve3Date).format('YYYY-MM-DD')}
                                                                    onChange={handleChange}
                                                                    disabled={isDisabled}
                                                                    style={{ ...dateStyle, marginLeft: '-5px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>





                                                    <h5 style={{ textAlign: "center", fontSize: 24, marginTop: '100px' }}>  Corrective Action </h5>

                                                    <Row className="mx-auto justify-content-center">
                                                        <Col sm={2}>
                                                            <FormGroup>
                                                                <Label for="InterimAction" style={{ marginLeft: '-290px' }}>Interim Action Taken</Label>
                                                                <Input
                                                                    id="InterimAction"
                                                                    name="InterimAction"
                                                                    value={formData.InterimAction === null ? '' : formData.InterimAction}
                                                                    onChange={handleChange}
                                                                    type="textarea"
                                                                    disabled={isDisabled}
                                                                    style={{ ...textareaStyle, height: '200px', width: '220px', marginLeft: '-130px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <FormGroup>
                                                                <Label for="RootCause" style={{ marginLeft: '-110px' }}>Root Cause Determination</Label>
                                                                <Input
                                                                    id="RootCause"
                                                                    name="RootCause"
                                                                    value={formData.RootCause === null ? '' : formData.RootCause}
                                                                    onChange={handleChange}
                                                                    type="textarea"
                                                                    disabled={isDisabled}
                                                                    style={{ ...textareaStyle, height: '200px', width: '220px', marginLeft: '-60px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={2} >
                                                            <FormGroup>
                                                                <Label for="CPA" style={{ marginLeft: '0px' }}>Correct/Prevent Action</Label>
                                                                <Input
                                                                    id="CPA"
                                                                    name="CPA"
                                                                    value={formData.CPA === null ? '' : formData.CPA}
                                                                    onChange={handleChange}
                                                                    type="textarea"
                                                                    disabled={isDisabled}
                                                                    style={{ ...textareaStyle, height: '200px', width: '220px', marginLeft: '10px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={2} >
                                                            <FormGroup>
                                                                <Label for="CPAFollowUP" style={{ marginLeft: '50px' }}>Follow Up</Label>
                                                                <Input
                                                                    id="CPAFollowUP"
                                                                    name="CPAFollowUP"
                                                                    value={formData.CPAFollowUP === null ? '' : formData.CPAFollowUP}
                                                                    onChange={handleChange}
                                                                    type="textarea"
                                                                    disabled={isDisabled}
                                                                    style={{ ...textareaStyle, height: '200px', width: '220px', marginLeft: '80px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>


                                                    <Row className="mx-auto justify-content-center" style={{ marginTop: '20px' }}>
                                                        <Col sm={2}>
                                                            <FormGroup>
                                                                <Label for="CPASign" style={{ marginLeft: '-250px' }}>Signature</Label>
                                                                <Input
                                                                    id="CPASign"
                                                                    name="CPASign"
                                                                    value={formData.CPASign === null ? '' : formData.CPASign}
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    disabled={isDisabled}
                                                                    style={{ ...textStyle, width: '150px', marginLeft: '-70px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={2} >
                                                            <FormGroup>
                                                                <Label for="CPADate" style={{ marginLeft: '-105px' }}>Implementation Date</Label>
                                                                <Input
                                                                    id="CPADate"
                                                                    name="CPADate"
                                                                    value={formData.CPADate === null ? '' : moment(formData.CPADate).format('YYYY-MM-DD')}
                                                                    onChange={handleChange}
                                                                    type="date"
                                                                    disabled={isDisabled}
                                                                    style={{ ...dateStyle, marginLeft: '-40px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={2} >
                                                            <FormGroup check>
                                                                <Label check style={{ marginLeft: '30px' }}>
                                                                    <Input
                                                                        type="checkbox"
                                                                        id="ContinuousImprovement"
                                                                        name="ContinuousImprovement"
                                                                        checked={formData.ContinuousImprovement}
                                                                        onChange={handleChange}
                                                                        disabled={isDisabled}
                                                                        style={{ marginLeft: '-10px' }}
                                                                    />
                                                                    {' '}Continuous Improvement Result from this CR?
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={2} >
                                                            <FormGroup check>
                                                                <Label check style={{ marginLeft: '50px' }}>
                                                                    <Input
                                                                        type="checkbox"
                                                                        id="DidAssigneeCompleteSatisfactorily"
                                                                        name="DidAssigneeCompleteSatisfactorily"
                                                                        checked={formData.DidAssigneeCompleteSatisfactorily}
                                                                        onChange={handleChange}
                                                                        disabled={isDisabled}
                                                                        style={{ marginLeft: '-10px' }}
                                                                    />
                                                                    {' '}Did Assignee Complete Satisfactorily?
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>


                                                    <h5 style={{ textAlign: "center", fontSize: 24, marginTop: '100px', marginBottom: '20px' }}>  CA Report Status </h5>

                                                    <Row className=" justify-content-center" style={{ marginLeft: '-0px' }}>
                                                        <Col sm={2}>
                                                            <FormGroup>
                                                                <Label for="CompleteDateCA" style={{ marginLeft: '-115px' }}>Completion Date</Label>
                                                                <Input
                                                                    id="CompleteDateCA"
                                                                    name="CompleteDateCA"
                                                                    value={formData.CompleteDateCA === null ? '' : moment(formData.CompleteDateCA).format('YYYY-MM-DD')}
                                                                    onChange={handleChange}
                                                                    type="date"
                                                                    disabled={isDisabled}
                                                                    style={{ ...dateStyle, marginLeft: '-30px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <FormGroup>
                                                                <Label for="Approve1CA" style={{ marginLeft: '-110px' }}>QA Manager</Label>
                                                                <Input
                                                                    id="Approve1CA"
                                                                    name="Approve1CA"
                                                                    value={formData.Approve1CA === null ? '' : formData.Approve1CA}
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    disabled={isDisabled}
                                                                    style={{ ...textStyle, width: '170px', marginLeft: '-10px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={2} >
                                                            <FormGroup>
                                                                <Label for="Approve2CA" style={{ marginLeft: '-140px' }}>Assignee</Label>
                                                                <Input
                                                                    id="Approve2CA"
                                                                    name="Approve2CA"
                                                                    value={formData.Approve2CA === null ? '' : formData.Approve2CA}
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    disabled={isDisabled}
                                                                    style={{ ...textStyle, width: '170px', marginLeft: '-10px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={2} >
                                                            <FormGroup>
                                                                <Label for="Approve3CA" style={{ marginLeft: '-160px' }}>Other</Label>
                                                                <Input
                                                                    id="Approve3CA"
                                                                    name="Approve3CA"
                                                                    value={formData.Approve3CA === null ? '' : formData.Approve3CA}
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    disabled={isDisabled}
                                                                    style={{ ...textStyle, width: '170px', marginLeft: '-10px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={2} >
                                                            <FormGroup>
                                                                <Label for="ProposedAuditDate" style={{ marginLeft: '-15px', marginTop: '-50px' }}>Proposed Audit Date (Optional)</Label>
                                                                <Input
                                                                    id="ProposedAuditDate"
                                                                    name="ProposedAuditDate"
                                                                    value={formData.ProposedAuditDate === null ? '' : moment(formData.ProposedAuditDate).format('YYYY-MM-DD')}
                                                                    onChange={handleChange}
                                                                    type="date"
                                                                    disabled={isDisabled}
                                                                    style={{ ...dateStyle, marginLeft: '-10px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>





                                                    <Row className="mx-auto justify-content-center" style={{ marginBottom: '20px' }}>
                                                        <Col sm={2} >
                                                            <FormGroup>
                                                                <Label check style={{ marginLeft: '-40px' }}>
                                                                    <Input
                                                                        type="checkbox"
                                                                        id="RptCloseCA"
                                                                        name="RptCloseCA"
                                                                        checked={formData.RptCloseCA}
                                                                        onChange={handleChange}
                                                                        disabled={isDisabled}

                                                                    />
                                                                    {' '}Report Closed?
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={2} >
                                                            <FormGroup>
                                                                <Label for="exampleEmail" style={{ marginLeft: '-175px' }}>Date</Label>
                                                                <Input
                                                                    id="Approve1DateCA"
                                                                    name="Approve1DateCA"
                                                                    value={formData.Approve1DateCA === null ? '' : moment(formData.Approve1DateCA).format('YYYY-MM-DD')}
                                                                    onChange={handleChange}
                                                                    type="date"
                                                                    disabled={isDisabled}
                                                                    style={{ ...dateStyle, marginLeft: '-17px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={2} >
                                                            <FormGroup>
                                                                <Label for="exampleEmail" style={{ marginLeft: '-175px' }}>Date</Label>
                                                                <Input
                                                                    id="Approve2DateCA"
                                                                    name="Approve2DateCA"
                                                                    value={formData.Approve2DateCA === null ? '' : moment(formData.Approve2DateCA).format('YYYY-MM-DD')}
                                                                    onChange={handleChange}
                                                                    type="date"
                                                                    disabled={isDisabled}
                                                                    style={{ ...dateStyle, marginLeft: '-14px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={2} >
                                                            <FormGroup>
                                                                <Label for="exampleEmail" style={{ marginLeft: '-175px' }}>Date</Label>
                                                                <Input
                                                                    id="Approve3DateCA"
                                                                    name="Approve3DateCA"
                                                                    value={formData.Approve3DateCA === null ? '' : moment(formData.Approve3DateCA).format('YYYY-MM-DD')}
                                                                    onChange={handleChange}
                                                                    type="date"
                                                                    disabled={isDisabled}
                                                                    style={{ ...dateStyle, marginLeft: '-15px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={2} >
                                                            <FormGroup>
                                                                <Label for="exampleEmail" style={{ marginLeft: '-90px' }}>Last Update</Label>
                                                                <Input
                                                                    id="LastUpdate"
                                                                    name="LastUpdate"
                                                                    value={formData.LastUpdate === null ? '' : moment(formData.LastUpdate).format('YYYY-MM-DD')}
                                                                    onChange={handleChange}
                                                                    type="date"
                                                                    disabled={isDisabled}
                                                                    style={{ ...dateStyle, marginLeft: '2px' }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                </ModalBody>
                                                <Button className="justify-content-center" style={{ fontSize: '18px', padding: '10px 20px', backgroundColor: '#025444' }} color="primary" >
                                                    Submit New Report
                                                </Button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            }
        </div >
    );
}
export default AddCR