import React, { useState } from "react";
import { NavLink as ReactLink, useNavigate } from "react-router-dom";
import {
    Collapse, Nav, NavItem, Navbar, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, NavbarToggler, Button
} from "reactstrap";
import { useEffect } from 'react';
import axios from "axios";
//import { useMsal } from "@azure/msal-react";
//import { loginRequest } from "./authConfig";
import { log } from "console";
import APICalls from "./APICalls"
import { useMsal } from "@azure/msal-react";


function Header2({ }) {
    const navigate = useNavigate();
    const { accounts } = useMsal();
    const activeAccount = accounts[0];
    const { instance } = useMsal();
    const [user, setUser] = useState();
    const apiCalls = new APICalls();
    const [isOpen, setIsOpen] = useState(true);



    useEffect(() => {
        if (localStorage.getItem('user') == null) {
            GetUser();
        }
        else {
            setUser(JSON.parse(localStorage.getItem('user') as string));
        }
    }, []);

    const GetUser = async () => {
        const response = await apiCalls.GetCQSUser();
        const data = JSON.parse(response.data);
        setUser(data);
        localStorage.setItem('user', JSON.stringify(data));
    }

    const handeClick = () => {
        setIsOpen(!isOpen);
        console.log(isOpen);
    }

    const logout = async () => {
        delete axios.defaults.headers.common['Authorization'];
        instance.logout();
        navigate("/login");
      }

    return (
        <div  >
            <Navbar style={{ backgroundColor: "#025444" }}
                className="my-2"
                direction="down"
                expand="md">

                <Nav >
                    <NavbarToggler style={{ backgroundColor: "white" }} onClick={handeClick} />
                    <Collapse isOpen={isOpen} navbar>


                        <div className="w-25 p-2 mt-n1  "  >
                            {/*
                            <NavItem>
                                
                                <UncontrolledDropdown setActiveFromChild>
                                    <DropdownToggle
                                        caret
                                        className="nav-link"
                                        tag="a"
                                        style={{ color: "white", cursor: 'pointer', padding: '20px', width: '80px' }}
                                    >
                                        Navigate
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag={ReactLink} to="/conditionreports/" style={{ display: 'block' }}>All Reports</DropdownItem>
                                        <DropdownItem tag={ReactLink} to="/menu/" style={{ display: 'block' }}>Menu</DropdownItem>
                                        <DropdownItem tag={ReactLink} to="/login/" style={{ display: 'block' }}>Logout</DropdownItem>
                                    </DropdownMenu>
                                    
                                </UncontrolledDropdown>
    
                            </NavItem>
*/}

                        </div>
                        <img style={{ width: 350, height: 70, borderColor: "#0d0673", marginLeft: '550px' }} src="/Magni.jpg" alt="MagniDev Logo" />
                        <Button color="primary" style={{ fontSize: '18px', padding: '5px 10px', backgroundColor: '#FFFFFF', color: '#000000', marginLeft: '500px' }} onClick={logout} >Logout</Button>

                    </Collapse>
                </Nav>
            </Navbar>
        </div>
    );
}
export default Header2