import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Header2 from "./Header2";

function Menu() {
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10vh auto 0",
    height: "50vh",
    width: "80vh",
    border: "3px solid black", // Add a black border
    borderRadius: "50px", // curved corners
    padding: "20px", // padding inside box
  };

  const buttonStyle = {
    padding: "20px",
    fontSize: "1.2rem",
    fontWeight: "bold",
    width: "375px",
    backgroundColor: "#025444",
  };

  const headerStyle = {
    fontSize: "1.5rem", // Increase header text size
    fontWeight: "bold",
  };

  const navigate = useNavigate();
  const handleNavigateToLogin = () => {
    navigate('/login');
  };



  return (
    <div>
      <Header2 />
      <div style={containerStyle}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", }}>
          <h2 style={{...headerStyle, marginBottom:'50px',fontSize:'48px'}}>Main Menu</h2>
          <div style={{ marginBottom: "20px" }}>
            <Link to="/conditionreports/">
              <button className="btn btn-primary btn-lg" style={{...buttonStyle, marginBottom:'30px'}}>
                View Log and Initiate/Edit CR's
              </button>
            </Link>
          </div>

          <button
            onClick={handleNavigateToLogin}
            style={{ fontSize: '24px', }}
          >
            Logout
          </button>
        </div>

      </div>
    </div>
    /*
    <div>
      <Header2 />
      <div style={containerStyle}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", }}>
          <h2 style={headerStyle}>Main Menu</h2>
          <div style={{ marginBottom: "20px" }}>
            <Link to="/conditionreports/">
              <button className="btn btn-primary btn-lg" style={buttonStyle}>
                View Log and Initiate/Edit CR's
              </button>
            </Link>
          </div>


          <div style={{ marginBottom: "20px" }}>
            <Link to="/crdetails">
              <button className="btn btn-primary btn-lg" style={buttonStyle}>
                View Log Continuous Improvement
              </button>
            </Link>
          </div>

          <div style={{ marginBottom: "20px" }}>
            <Link to="/InitiateAudit">
              <button className="btn btn-primary btn-lg" style={buttonStyle}>
                View Log and Initiate Internal Audit
              </button>
            </Link>
          </div>

          <div style={{ marginBottom: "50px" }}>
            <Link to="/Print">
              <button className="btn btn-primary btn-lg" style={buttonStyle}>
                Print Selection Menu
              </button>
            </Link>
          </div>
          <button
            onClick={handleNavigateToLogin}
            style={{ fontSize: '24px', }}
          >
            Logout
          </button>
        </div>

      </div>
    </div>

    
  */

  





  );

  
}

export default Menu;
