import React, { useEffect, useState } from 'react';
import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import * as msal from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from './authConfig';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import {  Button,Label, Input, Row, Col, FormGroup, Form, Alert, Spinner, Container } from "reactstrap";
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import qs from "qs";
import { useAuth } from './AuthContext';

const Login: React.FC = () => {
  const { accounts,instance } = useMsal();
  const { setIsAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const imgWidth = windowWidth > 768 ? '500px' : '110vw';
  const imgHeight = windowWidth > 768 ? '500px' : '30vh';


  const handleLogin = async (loginType: string) => {

    if (accounts.length > 0) {
        console.log('accounts!')
        setIsAuthenticated(true);
    }
    else
    {
        console.log(accounts);
    }

    setAnchorEl(null);

    try {
        if (loginType === "popup") {
            await instance.loginPopup(loginRequest).catch(err => console.log(err));
            
            

        } else if (loginType === "redirect") {
            await instance.loginRedirect(loginRequest).catch(err => console.log(err));
        }
    } catch (err) {
        console.error("Error during login:", err);
    }
}


useEffect(() => {
    if (accounts.length > 0) {
        setIsAuthenticated(true);
        navigate('/conditionreports');
    }


    const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
      
      // Clean up function
      return () => {
        window.removeEventListener('resize', handleResize);
      };
  }, [accounts]);


  return (
    <div style={{ overflowX: "hidden" }}>

      <Row>
      <img style={{ width: imgWidth, height: imgHeight }} src="image.jpg" alt='' />

        <Col md={5} className="mx-auto">
  <Container style={{ marginTop: 150 }}>
    <Row className="justify-content-md-center">
      <Col md={6}>
        <h1 style={{ color: "#0E226A", textAlign: 'center' }}>Welcome!</h1>
        <div style={{alignContent:'center'}}>
          <Button
              onClick={() => handleLogin("redirect")} key="loginRedirect"
              style={{backgroundColor:'#0E226A', borderColor:'#0E226A', width:'100%', marginTop:20}}
          >
              Login with Microsoft!
          </Button>
      </div>
      </Col>
    </Row>
  </Container>
        </Col>


      </Row>
    </div>
      
  )
};

export default Login;