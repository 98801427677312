import logo from './logo.svg';
import './App.css';
import Menu from './Components/Menu'
import ConditionReports from './Components/ConditionReports'
import AddCR from './Components/AddCR'
import { BrowserRouter as Router, Routes, Route, Link, Outlet, useNavigate, Navigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import CRDetails from './Components/CRDetails'
import Login from './Components/Login'
import 'bootstrap/dist/css/bootstrap.min.css';
import { MsalProvider, useMsal } from "@azure/msal-react";
import { IPublicClientApplication, NavigationClient, } from "@azure/msal-browser";

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  const { accounts } = useMsal();
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate();
  const navigationClient = new NavigationClient();
  pca.setNavigationClient(navigationClient);

  const [token, setToken] = useState<string>('');



  // useEffect(() => {
  //   console.log(token)

  //   // If there are no accounts (user is not authenticated), navigate to the login page
  //   if (accounts.length === 0) {
  //     navigate('/login/');
  //   } else if (accounts[0].idToken) {
  //     const accessToken = accounts[0].idToken;
  //     setToken(accessToken); // Set the token in the state
  //     console.log(accessToken); // Log the token
  //   }

  //   console.log(token); // Log the token when it changes
  //   console.log(accounts)
  // }, [accounts, navigate]); // Ensure navigate is included in the dependencies array



  useEffect(() => {
    console.log(token)


    if (accounts.length > 0 && accounts[0].idToken) {
      const accessToken = accounts[0].idToken;
      setToken(accessToken); // Set the token in the state
      console.log(accessToken); // Log the token
    }

    console.log(token); // Log the token when it changes
    console.log(accounts)
  }, [accounts]);




  return (




    <MsalProvider instance={pca}>
       <Routes>

      <Route path="/login/" element={<Login />}>         </Route>
      <Route path="*" element={<Login />} />
        <Route path="/conditionreports/*" element={<ConditionReports />}>
        </Route>
        <Route path="/conditionreports/crdetails/:data" element={<CRDetails />}>
        </Route>
        <Route path="/conditionreports/addcr/" element={<AddCR />}>
        </Route>
        <Route path="/menu/" element={<Menu />}>
        </Route>

      </Routes>


    </MsalProvider>





  );

}

export default App;
