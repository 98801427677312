import React from "react";
import { useState, useEffect } from 'react';
import axios, { AxiosResponse } from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Input, Col, Row, Label, Button, Spinner } from "reactstrap";
import qs from "qs";
import { UserObject } from "./MagniTypes";

class APICalls {



  //private baseURL = "http://localhost:44358/";
  private baseURL = "https://magniapi.azurewebsites.net"

  public LoginUser = async (formData: any): Promise<boolean> => {
    const response = await axios.post(
      this.baseURL + '/authorize',
      qs.stringify({
        grant_type: 'password',
        username: formData.userName,
        password: formData.password,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    console.log(response);
    // Extract the access token and refresh token from the response
    const { access_token, refresh_token } = response.data;

    if (access_token === undefined || refresh_token === undefined) {
      return false;
    }
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('refresh_token', refresh_token);
    localStorage.setItem('userName', formData.userName);

    return true;

  }

  public GetUser = async (): Promise<UserObject> => {
    const response = await axios.get(
      this.baseURL + '/api/User/GetCQSUserObject')
    const data = JSON.parse(response.data);
    localStorage.setItem('user', JSON.stringify(data));
    return data as UserObject;
  }


  public GetAllReports = async () => {
    const response = await axios.get(
      `${this.baseURL}/api/ConditionReports/GetAllReports`
    );
    console.log(response)
    return response;
  }

  public GetActiveReports = async () => {
    const response = await axios.get(
      `${this.baseURL}/api/ConditionReports/GetActiveReports`
    );
    console.log(response)
    return response;
  }

  public GetClosedReports = async () => {
    const response = await axios.get(
      `${this.baseURL}/api/ConditionReports/GetClosedReports`
    );
    console.log(response)
    return response;
  }

  public GetReportByID = async (data: string) => {
    const response = await axios.get(
      `${this.baseURL}/api/ConditionReports/GetReportByID/${data}`)
    return response;
  }

  public GetProducts = async () => {
    const response = await axios.get(
      `${this.baseURL}/api/ConditionReports/GetCRProducts`)
    return response;
  }

  public GetInitiators = async () => {
    const response = await axios.get(
      `${this.baseURL}/api/ConditionReports/GetRequestors`)
    return response;
  }

  public GetAssignedToUsers = async () => {
    const response = await axios.get(
      `${this.baseURL}/api/ConditionReports/GetActAssignUsers`)
    return response;
  }

  public GetCustomers = async () => {
    const response = await axios.get(
      `${this.baseURL}/api/ConditionReports/GetCustomers`)
    return response;
  }

  public AddReport = async (data: any) => {
    const response = await axios.post(
      `${this.baseURL}/api/ConditionReports/AddReport`, data
    )
    return response;
  }

  public EditReport = async (data: string, data2: any) => {
    const response = await axios.post(
      `${this.baseURL}/api/ConditionReports/EditReport/${data}`, data2
    )
    return response;
  }

  public GetCQSUser = async () => {
    const response = await axios.get(
      `${this.baseURL}/api/User/GetCQSUserObject`
    )
    return response
  }









}


export default APICalls;
